import { AxiosResponse } from "axios";

import { api } from "@app/api/api";
import { ParamsDef } from "@app/types/route.types";

import { ShopsEndpointsEnum } from "../constants/shops.endpoints";
import { CardToDeliveryDef, ParamsListPacks } from "../types/shops.types";

const shopListApi = (params?: ParamsDef): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.SHOP_LIST;
  return api.get(url, { params });
};

const bannerListApi = (params?: ParamsDef): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.BANNER_LIST;
  return api.get(url, { params });
};

const labelListApi = (params?: ParamsDef): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.LABEL_LIST;
  return api.get(url, { params });
};

const getShopDetailApi = (id: string): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.SHOP_DETAIL.replace(":id", id);
  return api.get(url);
};

const getShopPacksApi = (params: ParamsListPacks): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.SHOP_PACKS;
  return api.get(url, { params });
};

const getPackDetailApi = ({
  shopId,
  packId,
}: {
  shopId: string;
  packId: string;
}): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.PACK_DETAIL.replace(":shopId", shopId).replace(
    ":packId",
    packId
  );
  return api.get(url);
};

const getListCardApi = ({
  shopId,
  packId,
  params,
}: {
  shopId: string;
  packId: string;
  params: ParamsDef;
}): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.CARD_LIST.replace(":id", shopId).replace(
    ":packId",
    packId
  );
  return api.get(url, { params });
};

const getShopListUserApi = (params?: ParamsDef): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.SHOP_LIST_USER;
  return api.get(url, { params });
};

const getCardListUserApi = ({
  shopId,
  params,
}: {
  shopId: string;
  params?: ParamsDef;
}): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.GET_CARD_LIST_USER.replace(":shopId", shopId);
  return api.get(url, { params });
};

const cardToDeliveryApi = (data: CardToDeliveryDef): Promise<AxiosResponse> => {
  const url = ShopsEndpointsEnum.CARD_TO_DELIVERY;
  return api.post(url, data);
};

const shopsApi = {
  shopListApi,
  bannerListApi,
  getShopDetailApi,
  getShopPacksApi,
  getPackDetailApi,
  getListCardApi,
  getShopListUserApi,
  getCardListUserApi,
  cardToDeliveryApi,
  labelListApi,
};

export default shopsApi;
