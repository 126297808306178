import { memo } from "react";

import BankTransfer from "../../Components/BankTransfer/BankTransfer";

const BankTransferNotifyScreen = () => {
  return (
    <BankTransfer
      title="ポイントを購入しました。"
      content={`この度はご利用いただきありがとうございました。\nご登録のメールアドレスに詳細をお送りいたしました。\nメールに記載のURLよりお手続きをお願いいたします。`}
    />
  );
};

export default memo(BankTransferNotifyScreen);
