/* eslint-disable no-irregular-whitespace */
import { ChangeEvent, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { useMount } from "ahooks";
import { Input, Checkbox } from "antd";
import cx from "classnames";
import { Link, useHistory, useParams } from "react-router-dom";

import Logo from "@app/assets/images/logo.png";
import Button from "@app/components/atoms/Button/Button";
import Form, { Item, useForm } from "@app/components/atoms/Form/Form";
import Image from "@app/components/atoms/Image/Image";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Helmet from "@app/components/molecules/Helmet/Helmet";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { PAGE_INFO } from "@app/constants/constants";
import { ERROR_MESSAGES } from "@app/constants/message.constants";
import TermsOfServiceScreen from "@app/features/static-page/screens/TermsOfServiceScreen/TermsOfServiceScreen";
import {
  emailRules,
  formatMessageError,
  passwordRules,
  phoneNumberRules,
} from "@app/helpers/validations.helper";
import { useAppDispatch } from "@app/redux/store";

import {
  postOfficialMember,
  CreateUserDataDef,
  TOKEN_STATUS,
  checkTokenValid,
  AuthPathsEnum,
} from "../../auth";
import styles from "./RegisterOfficialMemberScreen.module.scss";

enum Step {
  TypingInfo,
  ConfirmInfo,
  Notification,
}

type Params = {
  token: string;
};

type ResponseCheckToken = {
  auth: string;
  email: string;
};

const initialStep = (): Step => {
  const stepSession = sessionStorage.getItem("step");
  const infoUserTemporary = sessionStorage.getItem("info_user_temporary");
  if (stepSession && infoUserTemporary) {
    return JSON.parse(stepSession);
  }
  return Step.TypingInfo;
};

const initialFormData = (): CreateUserDataDef => {
  const infoUserTemporary = sessionStorage.getItem("info_user_temporary");
  if (infoUserTemporary) {
    return JSON.parse(infoUserTemporary);
  }
  return {} as CreateUserDataDef;
};

const RegisterOfficialMemberScreen = () => {
  const dispatch = useAppDispatch();
  const [tokenValid, setTokenValid] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step] = useState<Step>(initialStep);
  const [mailUser, setMailUser] = useState<string>();
  const [formData, setFormData] = useState<CreateUserDataDef>(initialFormData);
  const [isLoading, setIsLoading] = useState(true);

  const [form] = useForm();
  const params: Params = useParams();
  const history = useHistory();

  const checkToken = async () => {
    if (step === Step.Notification) {
      setIsLoading(false);
      sessionStorage.removeItem("info_user_temporary");
      sessionStorage.removeItem("step");
      return;
    }
    await dispatch(checkTokenValid({ token: params.token }))
      .then(unwrapResult)
      .then((res: ResponseCheckToken) => {
        if (res.auth === TOKEN_STATUS.VALID) {
          setTokenValid(true);
          if (!mailUser) {
            form.setFieldsValue({
              email: res.email,
              email_confirmation: res.email,
            });
            setMailUser(res.email);
          }
        } else {
          setTokenValid(false);
          history.push(AuthPathsEnum.TOKEN_EXPIRATION);
        }
      })
      .catch(() => {
        setTokenValid(false);
        history.push(AuthPathsEnum.TOKEN_EXPIRATION);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCreateUser = async () => {
    setIsSubmitting(true);
    await checkToken();
    tokenValid &&
      dispatch(
        postOfficialMember({
          data: {
            ...formData,
            agreement: undefined,
            confirmation_token: params.token,
          },
        })
      )
        .then(unwrapResult)
        .then(() => {
          sessionStorage.setItem("step", JSON.stringify(Step.Notification));
          history.push(window.location.pathname);
        })
        .catch(err => {
          openNotification({
            type: "warning",
            message: err.errors || ERROR_MESSAGES.UA_00,
          });
        })
        .finally(() => setIsSubmitting(false));
  };

  const saveDataToSession = (data: CreateUserDataDef): void => {
    sessionStorage.setItem("info_user_temporary", JSON.stringify(data));
  };

  const onFinishForm = async (dataForm: CreateUserDataDef) => {
    setFormData({ ...dataForm });
    sessionStorage.setItem("step", JSON.stringify(Step.ConfirmInfo));
    saveDataToSession(dataForm);
    history.push(window.location.pathname);
  };

  const handleRemoveSpace = (event: ChangeEvent<HTMLInputElement>) => {
    const SPACE_START_END = /^\s|\s$/g;
    const { value } = event.target;
    if (SPACE_START_END.test(value)) {
      const namePath = event.target.id;
      form.setFieldValue(namePath, value.trim());
      form.validateFields([namePath]);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ ...formData });
  }, [form, formData]);

  useMount(() => {
    checkToken();
  });

  if (isLoading) return <LoadingSpinner isFullScreen />;

  return (
    <div className={cx(styles.container)}>
      <div className="text-center">
        <Link to="/">
          <Image className={cx(styles.logo)} src={Logo} />
        </Link>
      </div>
      <div className="text-center">
        <div className={cx(styles.title)}>
          {step === Step.TypingInfo && "情報の入力"}
          {step === Step.ConfirmInfo && "情報の確認"}
          {step === Step.Notification && "会員登録完了"}
        </div>
        <div>
          {step === Step.TypingInfo && (
            <span className={cx(styles.usableContent)}>
              お客様の情報入力後、利用規約をお読みになり、「確認画面へ」ボタンを押してください。
            </span>
          )}

          {step === Step.ConfirmInfo && (
            <div>
              <span className={cx(styles.usableContent)}>
                以下の内容でよろしければ、「送信」ボタンを押してください。
              </span>
              <br />
              <span className={cx(styles.usableContent)}>
                内容を変更したい場合は「情報の修正」ボタンを押して情報を修正してください。
              </span>
            </div>
          )}
        </div>
      </div>

      {/* Typing info register screen */}
      {step === Step.TypingInfo && (
        <Form form={form} className={cx(styles.form)} onFinish={onFinishForm}>
          <div className={cx(styles.formTyping)}>
            <div>
              <Item
                name="username"
                label="ユーザー名(半角英数)"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: "ユーザーは、必ず指定してください。",
                  },
                  {
                    min: 1,
                    max: 255,
                    message: formatMessageError(ERROR_MESSAGES.UA_24, {
                      attribute: "ユーザー名",
                    }),
                  },
                ]}
              >
                <Input
                  placeholder="ユーザー名を入力"
                  onBlur={event => handleRemoveSpace(event)}
                />
              </Item>
            </div>
            <div>
              <Item
                name="tel"
                label="お電話番号(ハイフン抜き)"
                rules={phoneNumberRules}
              >
                <Input
                  maxLength={12}
                  placeholder="お電話番号を入力"
                  onChange={event => handleRemoveSpace(event)}
                />
              </Item>
            </div>
            <div>
              <Item
                name="email"
                label="メールアドレス(半角英数)"
                rules={[
                  ...emailRules,
                  {
                    type: "enum",
                    enum: [mailUser],
                    message: "認証に失敗しました。",
                  },
                ]}
              >
                <Input
                  placeholder="メールアドレスを入力"
                  onChange={event => {
                    handleRemoveSpace(event);
                  }}
                />
              </Item>
            </div>
            <div>
              <Item
                name="email_confirmation"
                label="メールアドレスを再入力(半角英数)"
                dependencies={["email"]}
                rules={[
                  ...emailRules,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("email") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "最初に入力したメールアドレスと同じ値を入力してください。"
                        )
                      );
                    },
                  }),
                  {
                    type: "enum",
                    enum: [mailUser],
                    message: "認証に失敗しました。",
                  },
                ]}
              >
                <Input
                  placeholder="メールアドレスを再入力"
                  onChange={event => handleRemoveSpace(event)}
                />
              </Item>
            </div>
            <div>
              <Item
                name="password"
                label="パスワード(半角英数 / 8文字以上50文字以内)"
                rules={passwordRules}
              >
                <Input.Password placeholder="パスワードを入力" />
              </Item>
            </div>
            <div>
              <Item
                name="password_confirmation"
                label="パスワードを再入力(半角英数 / 8文字以上50文字以内)"
                dependencies={["password"]}
                rules={[
                  ...passwordRules,
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "最初に入力したパスワードと同じ値を入力してください。"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password maxLength={50} placeholder="パスワードを入力" />
              </Item>
            </div>
            <div>
              <div className="font-12 font-weight-medium mb-2">利用規約</div>
              <div className={cx(styles.term)}>
                <TermsOfServiceScreen />
              </div>
            </div>
            <div className="text-center">
              <Item valuePropName="checked" name="agreement">
                <Checkbox className="flex-center">
                  利用規約に同意して会員登録
                </Checkbox>
              </Item>
            </div>
          </div>
          <Item shouldUpdate>
            {({ getFieldValue }) => (
              <div className={cx(styles.btnGroup)}>
                <Button
                  type="primary"
                  danger
                  className={cx(styles.btn)}
                  disabled={!getFieldValue("agreement")}
                  htmlType="submit"
                >
                  確認画面へ
                </Button>
              </div>
            )}
          </Item>
        </Form>
      )}

      {/* Confirm info screen */}
      {step === Step.ConfirmInfo && (
        <>
          <Helmet title={PAGE_INFO["UA-0020"].title} />
          <div className={cx(styles.formConfirm)}>
            <div>
              <div className="font-12 font-weight-medium">ユーザー名</div>
              <div className="break-word">{formData.username}</div>
            </div>
            <div>
              <div className="font-12 font-weight-medium">お電話番号</div>
              <div>{formData.tel}</div>
            </div>
            <div>
              <div className="font-12 font-weight-medium">メールアドレス</div>
              <div className="break-word">{formData.email}</div>
            </div>
            <div>
              <div className="font-12 font-weight-medium">パスワード</div>
              <div>************</div>
            </div>
            <div>
              <div className="font-12 font-weight-medium">利用規約</div>
              <div>同意</div>
            </div>
          </div>
          <div className={cx(styles.btnGroup)}>
            <Button
              type="ghost"
              danger
              className={cx(styles.btn)}
              onClick={() => {
                saveDataToSession(formData);
                sessionStorage.setItem("step", JSON.stringify(Step.TypingInfo));
                history.push(window.location.pathname);
              }}
              withLeftArrow
            >
              情報の修正
            </Button>
            <Button
              type="primary"
              danger
              htmlType="button"
              className={cx(styles.btn)}
              disabled={isSubmitting}
              onClick={handleCreateUser}
              withRightArrow
            >
              送信
            </Button>
          </div>
        </>
      )}

      {/* Notification register success screen */}
      {step === Step.Notification && (
        <>
          <Helmet title={PAGE_INFO["UA-0021"].title} />
          <div className="text-center">
            <div>
              <span>会員登録ありがとうございました。</span>
            </div>
            <div>
              <span>下記ボタンよりログインが行えます。</span>
            </div>
          </div>
          <div className={cx(styles.btnGroupLogin, "text-center")}>
            <Button
              type="primary"
              danger
              className={cx(styles.btn)}
              htmlType="button"
              withRightArrow
              to={AuthPathsEnum.LOGIN}
            >
              ログイン
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default RegisterOfficialMemberScreen;
