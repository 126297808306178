import { memo, ReactNode } from "react";

import cx from "classnames";

import NoImage from "@app/assets/images/no_image_card.png";

import Image from "../Image/Image";
import styles from "./CardZoom.module.scss";

interface CardZoomProps {
  isShowButtonZoom: boolean;
  className: "medium" | "big";
  title: string | ReactNode;
  imageUrl: string | null;
  handleZoomCard?: () => void;
}

const CardZoom = ({
  isShowButtonZoom,
  className,
  title,
  imageUrl,
  handleZoomCard,
}: CardZoomProps) => {
  return (
    <div className={cx(styles[className])}>
      <div className="position-relative">
        <Image className={cx(styles.cardImg)} src={imageUrl ?? NoImage} />
        {isShowButtonZoom && (
          <div className={cx(styles.zoom)} onClick={handleZoomCard} aria-hidden>
            <span className={cx("icon-search-icon", styles.zoomIcon)} />
          </div>
        )}
      </div>
      <div className={cx(styles.title)}>{title}</div>
    </div>
  );
};
export default memo(CardZoom);
