import { memo, useCallback, useEffect, useRef, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { useInViewport } from "ahooks";
import { Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useParams } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { openNotification } from "@app/components/molecules/Notification/notification";
import ExchangeGachaModal from "@app/features/gacha-histories/components/ExchangeGachaModal/ExchangeGachaModal";
import GachaCard from "@app/features/gacha-histories/components/GachaCard/GachaCard";
import {
  CARD_STATUS,
  cleanGachaHistoriesChecked,
  exchangeCardToPoint,
  selectAllGachaHistories,
  setGachaHistoriesChecked,
} from "@app/features/gacha-histories/gacha-histories";
import { getQueryParams } from "@app/helpers/queryParams/queryParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import {
  getCardListUser,
  ListCard,
  ShopsPathsEnum,
  SHOP_LIST_USER_LIMIT,
  updateGettingCards,
  updateListCardUser,
} from "../../shops";
import OrderDialog from "../OrderDialog/OrderDialog";
import styles from "./ListGachaCard.module.scss";

const ListGachaCard = () => {
  const { stt } = getQueryParams(window.location.search);
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { md } = useBreakpoint();

  const {
    gacha_histories: { gachaHistoriesChecked },
    shops: { listCardUser },
  } = useAppSelector((state: RootState) => state);

  const [isOpenExchangeModal, setIsOpenExchangeModal] =
    useState<boolean>(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpenOrderDialog, setIsOpenOrderDialog] = useState<boolean>(false);

  const [page, setPage] = useState(1);

  const btnRef = useRef(null);
  const [inViewport] = useInViewport(btnRef, {
    rootMargin: "-110px",
  });

  const fetchListCardUser = useCallback(
    (currentPage: number) => {
      dispatch(updateGettingCards(true));
      dispatch(
        getCardListUser({
          shopId: id,
          params: {
            page: currentPage,
            statuses: [String(stt)],
            use_page: true,
            limit: SHOP_LIST_USER_LIMIT,
          },
        })
      )
        .then(unwrapResult)
        .then((res: ListCard) => {
          dispatch(updateListCardUser(res));
        })
        .finally(() => dispatch(updateGettingCards(false)));
    },
    [dispatch, id, stt]
  );

  useEffect(() => {
    fetchListCardUser(1);
    const controller = new AbortController();
    return () => {
      dispatch(updateListCardUser(null));
      controller.abort();
    };
  }, [dispatch, fetchListCardUser]);

  const fetchMoreData = async () => {
    setPage(page + 1);
    fetchListCardUser(page + 1);
  };

  const handleExchangeGacha = () => {
    const data = {
      card_ids: gachaHistoriesChecked.map(item => item.id),
    };
    setIsSubmitting(true);
    dispatch(exchangeCardToPoint(data))
      .then(unwrapResult)
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        openNotification({ type: "warning", message: err.errors });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <div className={cx(styles.root)}>
      {!listCardUser ? (
        <div className="flex-1">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          {!listCardUser.cards.length ? (
            <p
              className={`text-center no-data text-primary font-${
                md ? 16 : 14
              } mb-0 flex-1`}
            >
              検索結果がありません。
            </p>
          ) : (
            <InfiniteScroll
              className={`${md ? "pb-10" : "pb-8"}`}
              dataLength={listCardUser.cards.length}
              next={fetchMoreData}
              hasMore={
                listCardUser.cards.length < listCardUser.page_info.total_count
              }
              loader={<LoadingSpinner />}
            >
              <Row gutter={[md ? 32 : 23, 40]}>
                {listCardUser?.cards.map(item => (
                  <Col key={item.id} md={4} sm={8} xs={12}>
                    <GachaCard
                      card={item}
                      isNotShowIconCheck={stt !== CARD_STATUS.SOLD}
                      handleChecked={() =>
                        dispatch(setGachaHistoriesChecked(item))
                      }
                    />
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          )}

          <div className={cx(styles.btnMain)}>
            <div className={cx(styles.btnBox, "container")}>
              {stt === CARD_STATUS.SOLD && (
                <div className="d-flex">
                  <Button
                    className={cx(styles.btn, `${md ? "mr-4" : "mr-2"}`)}
                    disabled={gachaHistoriesChecked.length === 0}
                    onClick={() => setIsOpenExchangeModal(true)}
                  >
                    Pt変換
                  </Button>
                  <Button
                    disabled={gachaHistoriesChecked.length === 0}
                    danger
                    className={cx(styles.btn)}
                    onClick={() => setIsOpenOrderDialog(true)}
                  >
                    発送
                  </Button>
                </div>
              )}
              <div ref={btnRef}>
                <Button
                  width="100%"
                  height={md ? "60px" : undefined}
                  borderRadius="30px"
                  className={`yellow font-${md ? 18 : 12} mt-4`}
                  onClick={() => {
                    history.push(ShopsPathsEnum.SHOP_LIST_USER);
                  }}
                >
                  獲得カード所持一覧へ
                </Button>
              </div>
              <div className="mt-4">
                <Button
                  className={cx(styles.btn, styles.btnLong, "gray")}
                  onClick={() => {
                    history.push(ShopsPathsEnum.SHOP_LIST);
                  }}
                >
                  ＴＯＰページへ
                </Button>
              </div>
            </div>
          </div>

          <ExchangeGachaModal
            open={isOpenExchangeModal}
            onClose={() => setIsOpenExchangeModal(false)}
            onSubmit={handleExchangeGacha}
            isDisableBtnSubmit={isSubmitting}
          />

          {stt === CARD_STATUS.SOLD && (
            <div className={cx(styles.buttonBox)}>
              <div className={`${md ? "font-15" : "font-12"} font-weight-bold`}>
                操作を選択してください。
              </div>
              <div
                className={`${md ? "flex-wrap mt-6" : "mt-3"} d-flex gap-10`}
              >
                <Button
                  height={`${md ? "31px" : "26px"}`}
                  width="100%"
                  shape="round"
                  className="gray-dark"
                  onClick={() =>
                    dispatch(selectAllGachaHistories(listCardUser.cards))
                  }
                >
                  <div className={`${md ? "font-14" : "font-11"}`}>
                    <span className="icon-check-solid mr-3 font-12 font-weight-bold" />
                    全て選択
                  </div>
                </Button>
                <Button
                  height={`${md ? "31px" : "26px"}`}
                  width="100%"
                  shape="round"
                  className="gray-dark"
                  onClick={() => dispatch(cleanGachaHistoriesChecked())}
                >
                  <div className={`${md ? "font-14" : "font-11"} flex-center`}>
                    <span className="mr-3 font-10">✕</span>
                    選択解除
                  </div>
                </Button>
              </div>
              <div
                className={`${md ? "flex-wrap" : ""} ${
                  inViewport ? "d-none" : ""
                } d-flex gap-10 mt-3`}
              >
                <Button
                  onClick={() => setIsOpenExchangeModal(true)}
                  width="100%"
                  shape="round"
                  height={`${md ? "55px" : "38px"}`}
                  disabled={gachaHistoriesChecked.length === 0}
                >
                  <div className={`${md ? "font-16" : "font-12"}`}>Pt変換</div>
                </Button>
                <Button
                  height={`${md ? "55px" : "38px"}`}
                  width="100%"
                  danger
                  shape="round"
                  disabled={gachaHistoriesChecked.length === 0}
                  onClick={() => setIsOpenOrderDialog(true)}
                >
                  <div className={`${md ? "font-16" : "font-12"}`}>発送</div>
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      <OrderDialog
        open={isOpenOrderDialog}
        handleCancel={() => setIsOpenOrderDialog(false)}
      />
    </div>
  );
};

export default memo(ListGachaCard);
