import { memo, useEffect, useState, useCallback } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Tabs } from "antd";
import cx from "classnames";
import { useSelector } from "react-redux";

import {
  getQueryParams,
  setQueryParams,
} from "@app/helpers/queryParams/queryParams";
import { objectKeyByValue } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ParamsDef } from "@app/types/route.types";

import {
  PACKS_LIST_STATUS,
  SHOP_PACK_LIMIT,
  STATUS_ALL,
  STATUS_ON_SALE,
  STATUS_SOLD_OUT,
  getShopPacks,
  updateGettingPacks,
  updateListPacks,
} from "../../shops";
import { ShopPacksResponseDef } from "../../types/shops.types";
import ListOripaCard from "../ListOripaCard/ListOripaCard";
import styles from "./ShopPacks.module.scss";

interface ShopPackProps {
  queryShopPacks: ParamsDef;
  pageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

const ShopPack = ({
  queryShopPacks,
  pageNumber,
  setPageNumber,
}: ShopPackProps) => {
  const { stt } = getQueryParams(window.location.search);
  const dispatch = useAppDispatch();

  const [status, setStatus] = useState<string | null>(
    stt ? (stt as string) : PACKS_LIST_STATUS[STATUS_ON_SALE]
  );

  const { isGettingPacks } = useSelector((state: RootState) => state.shops);

  const [isFirstLoading, setIsFirstLoading] = useState(false);

  const fetchDataListPack = useCallback(
    (currentPage: number) => {
      dispatch(updateGettingPacks(true));
      dispatch(
        getShopPacks({
          ...queryShopPacks,
          status,
          use_page: true,
          page: currentPage,
          limit: SHOP_PACK_LIMIT,
        })
      )
        .then(unwrapResult)
        .then((res: ShopPacksResponseDef) => {
          dispatch(updateListPacks(res));
        })
        .finally(() => {
          setIsFirstLoading(false);
          dispatch(updateGettingPacks(false));
        });
    },
    [dispatch, queryShopPacks, status]
  );

  useEffect(() => {
    setIsFirstLoading(true);
    fetchDataListPack(1);
    const controller = new AbortController();
    return () => {
      dispatch(updateListPacks(null));
      controller.abort();
    };
  }, [dispatch, fetchDataListPack]);

  const fetchMoreData = async () => {
    setPageNumber(pageNumber + 1);
    fetchDataListPack(pageNumber + 1);
  };

  return (
    <div className={cx(styles.tabs)}>
      <Tabs
        onChange={activeKey => {
          setStatus(PACKS_LIST_STATUS[Number(activeKey)]);
          setPageNumber(1);
          window.history.pushState(
            "",
            "",
            `${window.location.pathname}${
              PACKS_LIST_STATUS[Number(activeKey)] ? "?" : ""
            }${setQueryParams({
              stt: PACKS_LIST_STATUS[Number(activeKey)] ?? undefined,
            })}`
          );
        }}
        defaultActiveKey={stt ? objectKeyByValue(PACKS_LIST_STATUS, stt) : "1"}
      >
        <Tabs.TabPane disabled={isGettingPacks} tab="開催中" key="1">
          {status === PACKS_LIST_STATUS[STATUS_ON_SALE] && (
            <ListOripaCard
              fetchMoreData={fetchMoreData}
              isLoading={isFirstLoading}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane disabled={isGettingPacks} tab="開催終了" key="2">
          {status === PACKS_LIST_STATUS[STATUS_SOLD_OUT] && (
            <ListOripaCard
              fetchMoreData={fetchMoreData}
              isLoading={isFirstLoading}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane disabled={isGettingPacks} tab="すべて" key="3">
          {status === PACKS_LIST_STATUS[STATUS_ALL] && (
            <ListOripaCard
              fetchMoreData={fetchMoreData}
              isLoading={isFirstLoading}
            />
          )}
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default memo(ShopPack);
