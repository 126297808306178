import { PAGE_INFO } from "@app/constants/constants";
import { RouteItemDef } from "@app/types/route.types";

import DeliverySuccessScreen from "../screens/DeliverySuccessScreen/DeliverySuccessScreen";
import ListCardScreen from "../screens/ListCardScreen/ListCardScreen";
import PackDetailsScreen from "../screens/PackDetailsScreen/PackDetailsScreen";
import ShopListScreen from "../screens/ShopListScreen/ShopListScreen";
import ShopListUserDetailsScreen from "../screens/ShopListUserDetailsScreen/ShopListUserDetailsScreen";
import ShopListUserScreen from "../screens/ShopListUserScreen/ShopListUserScreen";
import { ShopsPathsEnum } from "../shops";

const SHOP_LIST_SCREEN: RouteItemDef = {
  id: "UA-0001",
  path: ShopsPathsEnum.SHOP_LIST,
  component: ShopListScreen,
  pageTitle: PAGE_INFO["UA-0001"].title,
};

const PACK_DETAILS_SCREEN: RouteItemDef = {
  id: "UA-0003",
  path: ShopsPathsEnum.PACK_DETAILS,
  component: PackDetailsScreen,
  pageTitle: PAGE_INFO["UA-0003"].title,
};

const LIST_CARD_SCREEN: RouteItemDef = {
  id: "UA-0004",
  path: ShopsPathsEnum.LIST_CARD,
  component: ListCardScreen,
  pageTitle: PAGE_INFO["UA-0004"].title,
};

const DELIVERY_SUCCESS_SCREEN: RouteItemDef = {
  id: "UA-0010",
  path: ShopsPathsEnum.DELIVERY_SUCCESS,
  component: DeliverySuccessScreen,
};

const SHOP_LIST_USER_SCREEN: RouteItemDef = {
  id: "UA-0011",
  path: ShopsPathsEnum.SHOP_LIST_USER,
  component: ShopListUserScreen,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO["UA-0011"].title,
};

const SHOP_LIST_USER_DETAILS_SCREEN: RouteItemDef = {
  id: "UA-0012",
  path: ShopsPathsEnum.SHOP_LIST_USER_DETAILS,
  component: ShopListUserDetailsScreen,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO["UA-0012"].title,
};

export const SHOPS_ROUTES = [
  SHOP_LIST_SCREEN,
  PACK_DETAILS_SCREEN,
  LIST_CARD_SCREEN,
  SHOP_LIST_USER_SCREEN,
  SHOP_LIST_USER_DETAILS_SCREEN,
  DELIVERY_SUCCESS_SCREEN,
];
