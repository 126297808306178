/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import qs from "qs";

import { ENV } from "@app/constants/env";
import {
  clearToken,
  GRANT_TYPE,
  postRefreshToken,
} from "@app/features/auth/auth";
import { trimValue } from "@app/helpers/transforms/trim-values";
import store from "@app/redux/store";
import { CancelSourceDef } from "@app/types/api.types";

import { AuthPathsEnum } from "../features/auth/constants/auth.paths";

/**
 * Adds authorization headers to API calls
 * @param {AxiosRequestConfig} request
 */
export const cancelToken: CancelSourceDef = {
  source: null,
};
const authInterceptor = async (request: AxiosRequestConfig) => {
  request.auth = {
    username: process.env.REACT_APP_AUTH_USER ?? "",
    password: process.env.REACT_APP_AUTH_PASSWORD ?? "",
  };
  cancelToken.source = axios.CancelToken.source();
  const requestConfig = trimValue(request) as any;
  requestConfig.cancelToken = cancelToken.source.token;
  const { accessToken } = store.getState().auth;
  if (accessToken) {
    requestConfig.headers.AuthorizationAPI = `Bearer ${accessToken}`;
  }
  return requestConfig;
};

/**
 * Axios error interceptor
 * @param {AxiosError} axiosError
 */

const errorInterceptor = async (axiosError: AxiosError) => {
  const statusCode = axiosError?.response?.status;
  switch (statusCode) {
    case 401:
      window.location.reload();
      break;
    case 429:
      // window.location.reload();
      break;
    case 408: {
      store.dispatch(clearToken());
      const redirect = qs.stringify({
        redirect: window.location.pathname + window.location.search,
      });
      window.location.href = `${AuthPathsEnum.LOGIN}?${redirect}`;
      break;
    }
    case 403:
      window.location.replace("/");
      break;
    case 404:
      window.location.replace(AuthPathsEnum.NOT_FOUND);
      break;
    case 503:
      if (window.location.pathname !== AuthPathsEnum.MAINTENANCE) {
        window.location.replace(AuthPathsEnum.MAINTENANCE);
      }
      break;
    default:
    // code block
  }
  return Promise.reject(axiosError);
};

/** Setup an API instance */
export const api = axios.create({
  baseURL: ENV.API_HOST,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

// Function that will be called to refresh authorization
const refreshAuthLogic = async () => {
  const { refreshToken } = store.getState().auth;
  if (refreshToken) {
    await store.dispatch(
      postRefreshToken({
        data: {
          refresh_token: refreshToken,
          grant_type: GRANT_TYPE.refresh_token,
        },
      })
    );
    return Promise.resolve();
  }
  return Promise.reject();
};

createAuthRefreshInterceptor(api, refreshAuthLogic);

api.interceptors.request.use(authInterceptor);
api.interceptors.response.use(undefined, errorInterceptor);
