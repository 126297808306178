import { useEffect, useState } from "react";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { compile } from "path-to-regexp";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ButtonBackPage from "@app/components/atoms/ButtonBackPage/ButtonBackPage";
import { MODE_ACTIONS } from "@app/constants/constants";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import LoadingSpinner from "../../../../components/atoms/LoadingSpinner/LoadingSpinner";
import CardAddress from "../../components/CardAddress/CardAddress";
import {
  getUserAddressList,
  UserAddressesPathsEnum,
} from "../../user-addresses";
import styles from "./ListUserAddressScreen.module.scss";

const ListUserAddressScreen = () => {
  const { md } = useBreakpoint();
  const dispatch = useAppDispatch();
  const { userAddressList } = useSelector(
    (state: RootState) => state.user_addresses
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [handleReload, setHandleReload] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    dispatch(getUserAddressList()).finally(() => setIsLoading(false));
  }, [dispatch, handleReload]);

  return (
    <div className={cx(styles.root)}>
      <div className={cx(styles.containerBox)}>
        {isLoading ? (
          <div className={md ? "pt-15" : "pt-5"}>
            <LoadingSpinner />
          </div>
        ) : (
          <div className={cx(styles.container)}>
            <ButtonBackPage className={cx("mb-4")} />
            <div className={`font-weight-bold font-${md ? 18 : 13}`}>
              発送先をお選びください。
            </div>
            {userAddressList?.user_addresses.length ? (
              <div>
                <div
                  className={cx(
                    md ? "font-15 mt-3" : "font-12 mt-2",
                    "font-weight-medium"
                  )}
                >
                  <p>
                    <span>メイン住所を変更する際には</span>
                    <span
                      className={cx(
                        "icon-check-icon mx-1 position-absolute",
                        md ? "font-24" : "font-18"
                      )}
                    />
                    <span className={cx(md ? "ml-8" : "ml-6")}>
                      マークをタップしてください。
                    </span>
                  </p>
                </div>
                <div className={cx(styles.cardAddressBox)}>
                  {userAddressList?.user_addresses.map(userAddress => (
                    <CardAddress
                      userAddress={userAddress}
                      handleReload={() => setHandleReload(!handleReload)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className={md ? "mt-5 pb-4 font-15" : "mt-1 pb-2 font-11"}>
                登録済み住所がありません。
              </div>
            )}
            <div
              className={cx(
                "flex-align-center cursor-pointer",
                md ? "mt-6" : "mt-4"
              )}
              aria-hidden
              onClick={() =>
                history.push(
                  compile(UserAddressesPathsEnum.CREATE_USER_ADDRESSES)({
                    mode: MODE_ACTIONS.CREATE,
                  })
                )
              }
            >
              <span className="icon-add-icon" />
              <div
                className={cx(
                  "text-green",
                  md ? "ml-3 font-20" : "ml-2 font-14"
                )}
              >
                新しい住所を追加
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListUserAddressScreen;
