import { CARD_TYPE } from "../../shops/constants/shops.constants";

export const CARD_STATUS = {
  ON_SALE: "on_sale",
  SOLD: "sold",
  WAIT_FOR_SEND: "wait_for_send",
  SENDING: "sending",
  SENT: "sent",
  EXCHANGED: "exchanged",
};

export const GACHA_TIMEOUT = 1500;

export const RARITY_CARDS = {
  [CARD_TYPE.BIG_HIT]: "SSR",
  [CARD_TYPE.MEDIUM_HIT]: "SR",
  [CARD_TYPE.SMALL_HIT]: "R",
  [CARD_TYPE.FAIL]: "R",
};

export const GACHA_STATUS = {
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  FAIL: "failed",
};

export const URL_ANIMATIONS_NORMAL: Record<string, string> = {
  top: `${process.env.REACT_APP_BASE_MEDIA_URL}/animations/アニメーション_TOP_SP.mp4`,
  big_hit: `${process.env.REACT_APP_BASE_MEDIA_URL}/animations/大当たり.mp4`,
  medium_hit: `${process.env.REACT_APP_BASE_MEDIA_URL}/animations/中当たり.mp4`,
  small_hit: `${process.env.REACT_APP_BASE_MEDIA_URL}/animations/はずれ.mp4`,
  fail: `${process.env.REACT_APP_BASE_MEDIA_URL}/animations/はずれ.mp4`,
};
