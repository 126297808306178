import { memo, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory, useLocation } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import ButtonBackPage from "@app/components/atoms/ButtonBackPage/ButtonBackPage";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { useAppDispatch } from "@app/redux/store";

import {
  METHOD_SELECTED,
  PAYMENT_TYPE_ID,
  PointExchangesPathsEnum,
  createPaymentBankTransfer,
  createPaymentLink,
} from "../../point-exchanges";
import styles from "./SelectPointTransferMethodScreen.module.scss";

const SelectPointTransferMethodScreen = () => {
  const { md } = useBreakpoint();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const {
    state,
  }: {
    state?: string;
  } = useLocation();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [methodSelected, setMethodSelected] = useState<number>(
    METHOD_SELECTED.CREDIT
  );

  useEffect(() => {
    if (!state) {
      history.push(PointExchangesPathsEnum.POINT_EXCHANGES);
    }
  }, [history, state]);

  const handleExchangePoints = () => {
    if (!state) return false;
    setIsSubmitting(true);
    if (methodSelected === METHOD_SELECTED.CREDIT) {
      return dispatch(
        createPaymentLink({
          id: state,
          payment_type_id: PAYMENT_TYPE_ID.PAYPAL,
        })
      )
        .then(unwrapResult)
        .then(data => {
          window.location.href = data.url;
        })
        .catch(err => {
          openNotification({ type: "warning", message: err.errors });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
    return dispatch(createPaymentBankTransfer(state))
      .then(unwrapResult)
      .then(() =>
        history.push(PointExchangesPathsEnum.BANK_TRANSFER_NOTIFY_SCREEN)
      )
      .catch(err => {
        openNotification({ type: "warning", message: err.errors });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className={cx(styles.root)}>
      <div className={cx(styles.container)}>
        <div className="container">
          <ButtonBackPage className={cx(md ? "mt-5" : "mt-3")} />
          <section>
            <Typography
              className={cx(
                md
                  ? "font-18 font-weight-bold mt-5"
                  : "font-12 font-weight-medium mt-4",
                styles.title
              )}
            >
              振込方法を選択してください。
            </Typography>
            <div
              className={cx("flex-justify-center gap-18", md ? "mt-5" : "mt-3")}
            >
              <div
                aria-hidden
                onClick={() => setMethodSelected(METHOD_SELECTED.CREDIT)}
                className={cx(styles.cardBox, "flex-space-between", {
                  [styles.cardBoxActive]:
                    methodSelected === METHOD_SELECTED.CREDIT,
                })}
              >
                <div
                  className={cx(
                    styles.cardImage,
                    "icon-card flex-center",
                    md ? "font-55" : "font-37"
                  )}
                />
                <Typography
                  className={cx(md ? "font-20" : "font-15", "font-weight-bold")}
                >
                  クレジットカード
                </Typography>
              </div>
              <div
                aria-hidden
                onClick={() => setMethodSelected(METHOD_SELECTED.BANK)}
                className={cx(styles.cardBox, "flex-space-between", {
                  [styles.cardBoxActive]:
                    methodSelected === METHOD_SELECTED.BANK,
                })}
              >
                <div
                  className={cx(
                    styles.cardImage,
                    "icon-bank flex-center",
                    md ? "font-75" : "font-60"
                  )}
                />
                <Typography
                  className={cx(md ? "font-20" : "font-15", "font-weight-bold")}
                >
                  銀行振込
                </Typography>
              </div>
            </div>

            <Typography className={cx(styles.textBox)}>
              ※クレジットカードを選択した場合は、決済ページが開きます。
              <br />
              ※銀行振込を選択した場合はメールにて詳細をお送りいたします。
              <br />
              ※銀行振込手数料はお客様負担でお願いいたします。
            </Typography>
            <div className="text-center">
              <Button
                className={cx(styles.btn)}
                type="primary"
                danger
                disabled={isSubmitting}
                withRightArrow
                shape="round"
                onClick={handleExchangePoints}
              >
                決定
              </Button>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default memo(SelectPointTransferMethodScreen);
