import { memo, useEffect, useMemo, useState, useCallback } from "react";

import { Carousel } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Link from "antd/lib/typography/Link";
import cx from "classnames";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import noImage from "@app/assets/images/no_image_shop.jpg";
import Image from "@app/components/atoms/Image/Image";
import { getQueryParams } from "@app/helpers/queryParams/queryParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";
import { ParamsDef } from "@app/types/route.types";

import ShopPack from "../../components/ShopPacks/ShopPacks";
import SlideImage from "../../components/SlideImage/SlideImage";
import {
  BANNERS_LIMIT,
  DELAY_AUTO_PLAY,
} from "../../constants/shops.constants";
import { getLabelList } from "../../redux/shops.slice";
import { getShopList, getBannerList } from "../../shops";
import styles from "./ShopListScreen.module.scss";

const ShopListScreen = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { md, xl } = useBreakpoint();

  const { shop_ids, label_ids } = getQueryParams(location.search);

  const [totalSlide, setTotalSlide] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [isDisablePointer, setIsDisablePointer] = useState(false);
  const [centerPadding, setCenterPadding] = useState(0);

  const { shopList, bannerList, labelList } = useSelector(
    (state: RootState) => state.shops
  );

  const [initParams, setInitParams] = useState<ParamsDef>({
    use_page: false,
    shop_ids: (shop_ids as string[]) ?? [],
    label_ids: (label_ids as string[]) ?? [],
  });

  const handleWindowResize = useCallback(() => {
    const { innerWidth } = window;
    const actualInnerWidth = document.body.clientWidth;
    const widthBannerSp = actualInnerWidth * 0.69;
    const shopTotal = shopList?.shops.length ?? 0;

    const dataWidthSm = (shopTotal * 59) / 2;
    const dataWidthMd = shopTotal * 205;

    const totalSm = dataWidthSm < innerWidth ? shopTotal / 2 : innerWidth / 59;
    const totalMd =
      dataWidthMd < innerWidth ? shopTotal : Math.floor(innerWidth / 205);

    setCenterPadding(
      xl
        ? (actualInnerWidth - 1092) / 2
        : (actualInnerWidth - widthBannerSp) / 2
    );
    setTotalSlide(md ? totalMd : totalSm);
  }, [md, shopList, xl]);

  useEffect(() => {
    const bannerParams: ParamsDef = {
      limit: BANNERS_LIMIT,
      use_page: true,
      shop_ids: undefined,
      label_ids: undefined,
    };
    dispatch(getBannerList(bannerParams));
    dispatch(
      getLabelList({
        ...initParams,
        label_ids: undefined,
      })
    );
    dispatch(getShopList({ ...initParams, shop_ids: undefined }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    handleWindowResize();

    const handleSetOffsetSlides = debounce(() => {
      handleWindowResize();
    }, 100);

    window.addEventListener("resize", handleSetOffsetSlides);

    return () => {
      window.removeEventListener("resize", handleSetOffsetSlides);
    };
  }, [handleWindowResize]);

  const handleFilter = useCallback(
    (key: string, params: ParamsDef) => {
      setIsDisablePointer(true);
      if (key === "shop_ids") {
        dispatch(
          getLabelList({
            ...params,
            label_ids: undefined,
            use_page: false,
          })
        ).then(() => {
          setIsDisablePointer(false);
        });
      } else {
        dispatch(
          getShopList({
            ...params,
            shop_ids: undefined,
            use_page: false,
          })
        ).then(() => {
          setIsDisablePointer(false);
        });
      }
    },
    [dispatch, setIsDisablePointer]
  );

  const dataLabels = useMemo(() => {
    const labelsFilter = initParams.label_ids;
    const labelData = labelList?.labels;
    return labelsFilter?.length
      ? labelData?.filter(item => labelsFilter?.includes(item.id.toString()))
      : labelData;
  }, [initParams.label_ids, labelList?.labels]);

  const dataShops = useMemo(() => {
    const shopsFilter = initParams.shop_ids;
    const shopData = shopList?.shops;
    return shopsFilter?.length
      ? shopData?.filter(item => shopsFilter?.includes(item.id.toString()))
      : shopData;
  }, [initParams.shop_ids, shopList?.shops]);

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const key = e.target.name as "shop_ids" | "label_ids";
    let newParams: ParamsDef = {};

    if (checked) {
      newParams = {
        ...initParams,
        [key]: initParams[key]?.concat([value]),
      };
    } else {
      newParams = {
        ...initParams,
        [key]: initParams[key]?.filter(item => item !== value),
      };
    }

    setInitParams(newParams);
    setPageNumber(1);
    handleFilter(key, newParams);
  };

  return (
    <div className={styles.root}>
      <Carousel
        key={bannerList?.banners.length}
        autoplay
        draggable
        swipeToSlide
        centerMode
        dots={false}
        centerPadding={`${centerPadding}px`}
        arrows
        className={styles.slider}
      >
        {bannerList?.banners.map(item => (
          <Link href={item.href_url} key={item.id} target="_blank">
            <Image
              key={Number(md)}
              src={md ? item.pc_image_url : item.sp_image_url}
              className={styles.banner}
            />
          </Link>
        ))}
      </Carousel>
      {!!dataShops?.length && (
        <Carousel
          key={`shops-${dataShops.length}`}
          infinite
          className={styles.shops}
          lazyLoad="progressive"
          slidesToShow={dataShops.length > 1 ? Math.ceil(totalSlide) : 1}
          rows={md ? 1 : 2}
          draggable
          initialSlide={1}
          swipeToSlide
          autoplay={!initParams.shop_ids?.length}
          autoplaySpeed={DELAY_AUTO_PLAY}
          dots={false}
        >
          {dataShops.map((item, index) => (
            <div
              aria-hidden="true"
              key={item.id}
              className={cx(
                styles.shop,
                isDisablePointer && styles.disablePointerEvents,
                !md && index % 2 !== 0 && styles.sole
              )}
            >
              <SlideImage
                checked={!!initParams.shop_ids?.includes(item.id.toString())}
                handleChange={handleCheckbox}
                value={item.id}
                src={item.image_url ?? noImage}
                name="shop_ids"
              />
            </div>
          ))}
        </Carousel>
      )}

      {!!dataLabels?.length && !isDisablePointer && (
        <Carousel
          infinite
          key={`labels-${dataLabels.length}`}
          lazyLoad="progressive"
          autoplaySpeed={DELAY_AUTO_PLAY}
          draggable
          slidesToShow={dataLabels.length > 1 ? 2 : 1}
          initialSlide={1}
          autoplay={!initParams.label_ids?.length}
          swipeToSlide
          className={styles.labels}
          dots={false}
        >
          {dataLabels.map(item => (
            <div
              className={cx(
                styles.label,
                isDisablePointer && styles.disablePointerEvents,
                "position-relative"
              )}
              key={item.id}
            >
              <SlideImage
                checked={!!initParams.label_ids?.includes(item.id.toString())}
                handleChange={handleCheckbox}
                src={item.image_url}
                value={item.id.toString()}
                name="label_ids"
              />
            </div>
          ))}
        </Carousel>
      )}
      {(dataLabels?.length || dataShops?.length) && (
        <p
          className={cx(
            "container",
            "text-black",
            md ? "my-7 font-14" : "font-12 my-4"
          )}
        >
          ※アイコンをタップでカテゴリーの絞り込みが可能です。選択状態を解除する場合は、再度アイコンをタップしてください。
        </p>
      )}
      <ShopPack
        queryShopPacks={initParams}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </div>
  );
};

export default memo(ShopListScreen);
