import { memo } from "react";

import cx from "classnames";
import { useHistory } from "react-router-dom";

import Image from "@app/components/atoms/Image/Image";
import { convertMoney } from "@app/helpers/util.helper";

import {
  PACKS_LIST_STATUS,
  STATUS_SOLD_OUT,
} from "../../constants/shops.constants";
import { ShopsPathsEnum } from "../../shops";
import { Pack } from "../../types/shops.types";
import StatusPack from "../StatusPack/StatusPack";
import styles from "./OripaCard.module.scss";

const OripaCard = ({ pack }: { pack: Pack }) => {
  const history = useHistory();
  return (
    <div
      className={cx(styles.oripaContainer, {
        [styles.soldOut]: pack.status === PACKS_LIST_STATUS[STATUS_SOLD_OUT],
      })}
      aria-hidden
      onClick={() =>
        history.push(
          ShopsPathsEnum.PACK_DETAILS.replace(
            ":shopId",
            pack.shop_id.toString()
          ).replace(":packId", String(pack.id))
        )
      }
    >
      {pack.image_url ? (
        <Image className={cx(styles.image)} src={pack.image_url} />
      ) : (
        <div className={cx(styles.noImage)} />
      )}
      <div className={cx(styles.content, "py-4")}>
        <StatusPack status={pack.status} />
        <div className={cx(styles.title, "font-13 font-weight-bold pr-2 mt-1")}>
          {pack.title}
        </div>
      </div>
      <div className={cx(styles.pointBox)}>
        <div className={cx(styles.point, "font-12 font-weight-bold")}>
          {pack.number_point_one_roll ? "１口" : "10口"}
          <div className="flex-align-center ml-4">
            <span className="icon-point-icon font-16">
              <span className="path1" />
              <span className="path2" />
            </span>
            <div className="ml-1">
              {convertMoney(
                pack.number_point_one_roll ?? pack.number_point_ten_roll
              )}
            </div>
          </div>
        </div>
        <div className="font-10 font-weight-bold text-end mr-2">
          ({convertMoney(pack.number_card_remain)}/{" "}
          {convertMoney(pack.total_card)})
        </div>
      </div>
    </div>
  );
};

export default memo(OripaCard);
