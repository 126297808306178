import { useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Typography from "antd/lib/typography/Typography";
import cx from "classnames";
import qs from "qs";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { cancelToken } from "@app/api/api";
import Button from "@app/components/atoms/Button/Button";
import ButtonBackPage from "@app/components/atoms/ButtonBackPage/ButtonBackPage";
import Image from "@app/components/atoms/Image/Image";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Helmet from "@app/components/molecules/Helmet/Helmet";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { NUMBER_ROLL_GACHA, PAGE_INFO } from "@app/constants/constants";
import { AuthPathsEnum, getListSettings } from "@app/features/auth/auth";
import {
  getPackNumberRollUser,
  postGachaHistories,
} from "@app/features/gacha-histories/gacha-histories";
import useGetGachaHistories from "@app/features/gacha-histories/hooks/useGetGachaHistories/useGetGachaHistories";
import { convertMoney } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import ButtonGacha from "../../../gacha-histories/components/ButtonGacha/ButtonGacha";
import ModalConfirmGacha from "../../components/ModalConfirmGacha/ModalConfirmGacha";
import ShopInfo from "../../components/ShopInfo/ShopInfo";
import StatusPack from "../../components/StatusPack/StatusPack";
import {
  getPackDetail,
  getShopDetail,
  PACKS_LIST_STATUS,
  resetPackDetail,
  resetShopDetail,
  ShopsPathsEnum,
  STATUS_SOLD_OUT,
  TIME_INTERVAL,
} from "../../shops";
import styles from "./PackDetailsScreen.module.scss";

const PackDetailsScreen = () => {
  const { md } = useBreakpoint();
  const { shopId, packId } = useParams<{ shopId: string; packId: string }>();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    auth: { currentUser, isMaintenanceGacha },
    shops: { packDetail },
    gacha_histories: { numberRollPack },
  } = useAppSelector((state: RootState) => state);
  const history = useHistory();

  const [gachaCountSelect, setGachaCountSelect] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getGachaHistories] = useGetGachaHistories(setIsSubmitting);

  const { shopDetail } = useAppSelector((state: RootState) => state.shops);

  useEffect(() => {
    dispatch(resetPackDetail());
    dispatch(getListSettings());
    dispatch(getPackDetail({ shopId, packId }));
    dispatch(getPackNumberRollUser({ pack_id: packId }));

    const fetchData = setInterval(() => {
      if (!document.hidden) {
        dispatch(getListSettings());
        dispatch(getPackDetail({ shopId, packId }));
      }
    }, TIME_INTERVAL);
    return () => {
      clearInterval(fetchData);
    };
  }, [dispatch, shopId, packId]);

  useEffect(() => {
    if (shopId) {
      dispatch(getShopDetail(shopId));
    }
  }, [dispatch, shopId]);

  useEffect(() => {
    return () => {
      if (cancelToken.source) {
        cancelToken.source.cancel();
      }
      dispatch(resetPackDetail());
      dispatch(resetShopDetail());
    };
  }, [dispatch]);

  if (!packDetail) return <LoadingSpinner />;

  const handleSubmit = (gachaCount: number, pointCount: number) => {
    if (!packDetail) return;
    if (!currentUser) {
      history.push({
        pathname: AuthPathsEnum.LOGIN,
        search: qs.stringify({
          redirect: location.pathname + location.search,
        }),
      });
      return;
    }

    const isEnoughMoney = (currentUser?.total_point ?? 0) >= pointCount;

    if (currentUser?.no_show_alert_gacha_at && isEnoughMoney) {
      setIsSubmitting(true);
      dispatch(
        postGachaHistories({ pack_id: packDetail.id, type: String(gachaCount) })
      )
        .then(unwrapResult)
        .then(data => {
          getGachaHistories({ isGacha: true, id: String(data.id) });
        })
        .catch(err => {
          openNotification({ type: "warning", message: err.errors });
          setIsSubmitting(false);
        });
    } else {
      setGachaCountSelect(gachaCount);
    }
  };

  return (
    <div className={cx(styles.root)}>
      <Helmet
        title={
          packDetail?.title && shopDetail?.name
            ? `${shopDetail.name}｜${packDetail.title}｜トレビル`
            : ""
        }
        description={
          shopDetail?.name
            ? `${shopDetail.name}${PAGE_INFO["UA-0003"].description}`
            : ""
        }
      />
      <div className={cx(styles.main)}>
        <div className={cx(styles.ButtonBackPage)}>
          <ButtonBackPage
            path={ShopsPathsEnum.SHOP_LIST}
            className={cx("my-2")}
          />
        </div>
        <div className={cx(styles.imgBgBox)}>
          {packDetail.image_background_url ? (
            <Image
              src={packDetail.image_background_url}
              className={cx(styles.imgBg)}
            />
          ) : (
            <div className={cx(styles.noImage)} />
          )}
          <div className={cx(styles.content)}>
            <div className={cx(styles.contentBox, "pr-5")}>
              <div className={cx(styles.totalBox)}>
                <div
                  className={cx(
                    styles.point,
                    "flex-align-center flex-space-between px-5"
                  )}
                >
                  <div>
                    {packDetail.number_point_one_roll ? "１口" : "10口"}
                  </div>
                  <div className="flex-align-center">
                    <span
                      className={`${
                        md ? "font-25 mr-3 mt-1" : "font-22 mr-2"
                      } icon-point-icon `}
                    >
                      <span className="path1" />
                      <span className="path2" />
                    </span>
                    <div>
                      {convertMoney(
                        packDetail.number_point_one_roll ??
                          packDetail.number_point_ten_roll
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={cx(
                    styles.totalCard,
                    "flex-align-center flex-space-between px-5"
                  )}
                >
                  <div>在庫</div>
                  <div>
                    {convertMoney(packDetail.number_card_remain)}/
                    {convertMoney(packDetail.total_card)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cx(styles.container)}>
          <Row>
            <Col md={12} style={{ paddingRight: md ? 44 : 0, width: "100%" }}>
              <StatusPack status={packDetail.status} />
              <div className={cx(styles.packName)}>{packDetail.title}</div>
            </Col>
            <Col span={24} md={12} style={{ paddingLeft: md ? 66 : 0 }}>
              <div className="position-relative">
                {isMaintenanceGacha && (
                  <div className={cx("maintenance flex-center")}>
                    メンテンス中
                  </div>
                )}
                {packDetail.max_roll_one_user && (
                  <div
                    className={`${
                      md ? "flex-justify-end" : "flex-justify-center"
                    } mx-auto`}
                  >
                    <div
                      className={cx(styles.rollNumber, "flex-space-between")}
                    >
                      <Typography>残り購入回数</Typography>
                      <Typography>
                        {packDetail.max_roll_one_user - (numberRollPack ?? 0)}/
                        {packDetail.max_roll_one_user} 回
                      </Typography>
                    </div>
                  </div>
                )}
                <div
                  className={cx(
                    md ? "flex-space-between" : "flex-justify-center"
                  )}
                >
                  <ButtonGacha
                    numberGachaRoll={NUMBER_ROLL_GACHA.ONE}
                    numberPointRoll={
                      packDetail.number_point_one_roll ??
                      packDetail.number_point_ten_roll / NUMBER_ROLL_GACHA.TEN
                    }
                    className={cx(styles.btnGacha1)}
                    handleClick={handleSubmit}
                    isDisable={
                      packDetail.status ===
                        PACKS_LIST_STATUS[STATUS_SOLD_OUT] ||
                      isSubmitting ||
                      !packDetail.number_point_one_roll ||
                      (!!numberRollPack &&
                        packDetail.max_roll_one_user === numberRollPack)
                    }
                  />
                  <ButtonGacha
                    numberGachaRoll={NUMBER_ROLL_GACHA.TEN}
                    numberPointRoll={
                      packDetail.number_point_ten_roll ??
                      packDetail.number_point_one_roll * NUMBER_ROLL_GACHA.TEN
                    }
                    className={cx(styles.btnGacha10)}
                    isDisable={
                      packDetail.status ===
                        PACKS_LIST_STATUS[STATUS_SOLD_OUT] ||
                      isSubmitting ||
                      !packDetail.number_point_ten_roll ||
                      (!!numberRollPack &&
                        packDetail.max_roll_one_user === numberRollPack)
                    }
                    handleClick={handleSubmit}
                  />
                </div>
                <div
                  className={`${
                    md ? "flex-space-between" : "flex-justify-center"
                  } mx-auto`}
                >
                  <Button
                    shape="round"
                    className={cx(styles.btnDetails, "gray")}
                    onClick={() =>
                      history.push(
                        ShopsPathsEnum.LIST_CARD.replace(":id", shopId).replace(
                          ":packId",
                          packId
                        )
                      )
                    }
                  >
                    ガチャ詳細
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={cx(styles.info)}>
          <div className={styles.auto}>
            <StatusPack text="取扱店舗" className={cx(styles.mark)} />
            <ShopInfo shopDetail={shopDetail} />
          </div>
        </div>
        {packDetail && (
          <ModalConfirmGacha
            open={!!gachaCountSelect}
            pack_id={packDetail.id}
            shop_id={Number(shopId)}
            handleCancel={() => setGachaCountSelect(null)}
            gachaCount={gachaCountSelect ?? 0}
            numberPointRoll={
              gachaCountSelect === NUMBER_ROLL_GACHA.ONE
                ? packDetail.number_point_one_roll
                : packDetail.number_point_ten_roll
            }
          />
        )}
      </div>
    </div>
  );
};

export default PackDetailsScreen;
