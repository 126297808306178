import { memo, useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Checkbox, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import { Item } from "@app/components/atoms/Form/Form";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import ConfirmDialog from "@app/components/molecules/ConfirmDialog/ConfirmDialog";
import { openNotification } from "@app/components/molecules/Notification/notification";
import { LOCAL_STORAGE_BACK_PAGE_KEY } from "@app/constants/constants";
import { getCurrentUser, updateUser } from "@app/features/auth/auth";
import { StaticPagePathsEnum } from "@app/features/static-page/static-page";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch } from "@app/redux/store";

import Point from "../../Components/Point/Point";
import { PointExchangesPathsEnum } from "../../point-exchanges";
import { getListPoint } from "../../redux/point-exchanges.slice";
import styles from "./PointExchange.module.scss";

const PointExchangeScreen = () => {
  const dispatch = useAppDispatch();
  const { md } = useBreakpoint();
  const {
    state,
  }: {
    state?: number;
  } = useLocation();
  const history = useHistory();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isHiddenNotification, setIsHiddenNotification] =
    useState<boolean>(false);
  const [idPointSelect, setIdPointSelect] = useState<number>(0);

  const {
    point_exchange: { pointList },
    auth: { currentUser },
  } = useSelector((states: RootState) => states);

  const { Text } = Typography;

  const handleBackPage = () => {
    const backPage = localStorage.getItem(LOCAL_STORAGE_BACK_PAGE_KEY);
    if (
      !backPage ||
      backPage === PointExchangesPathsEnum.SELECT_POINT_TRANSFER_METHOD_SCREEN
    ) {
      return history.push("/");
    }
    return history.push(backPage);
  };

  const handleCreatePaymentLink = (id: number) => {
    if (isHiddenNotification) {
      dispatch(
        updateUser({
          no_show_alert_point_exchange: true,
        })
      )
        .then(unwrapResult)
        .then(() => dispatch(getCurrentUser()));
    }
    history.push(
      PointExchangesPathsEnum.SELECT_POINT_TRANSFER_METHOD_SCREEN,
      id
    );
  };

  const handleBuyPoint = (id: number) => {
    if (currentUser?.no_show_alert_point_exchange_at) {
      handleCreatePaymentLink(id);
    } else {
      setIsOpenModal(true);
      setIdPointSelect(id);
    }
  };

  const handleCancel = () => {
    setIsOpenModal(false);
    setIsHiddenNotification(false);
  };

  useEffect(() => {
    if (state) {
      openNotification({
        type: "success",
        message: `決済完了しましたので、${state.toLocaleString(
          "en-US"
        )}ポイントが加増されました。`,
      });
      dispatch(getCurrentUser());
      window.history.pushState("", "", PointExchangesPathsEnum.POINT_EXCHANGES);
    }
    dispatch(getListPoint({}));
    return () => {
      localStorage.removeItem(LOCAL_STORAGE_BACK_PAGE_KEY);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, dispatch]);

  return (
    <>
      <div className={cx(styles.root)}>
        <div className={cx(styles.container)}>
          <div>
            <Button
              withLeftArrow
              shape="round"
              className={cx("gray btn-small", md ? "mt-5" : "mt-3")}
              onClick={handleBackPage}
            >
              戻る
            </Button>
          </div>
          {!pointList ? (
            <LoadingSpinner />
          ) : (
            <div className={cx(styles.points)}>
              <div className={cx(styles.options)}>
                {pointList?.point_exchanges.map(info => (
                  <Point
                    key={info.id}
                    pointExchanges={info}
                    handleClick={handleBuyPoint}
                  />
                ))}
              </div>
              <div className={styles.textLink}>
                <Link to={StaticPagePathsEnum.COMMERCIAL_LAW}>
                  特定商法取引法・資金決済法に関して
                </Link>
              </div>
            </div>
          )}
        </div>
        <ConfirmDialog
          title="ポイント購入"
          cancelText="キャンセル"
          okText="購入ページへ"
          open={isOpenModal}
          width={md ? 504 : 333}
          handleCancel={handleCancel}
          handleSubmit={() => handleCreatePaymentLink(idPointSelect)}
          className={cx(styles.modal)}
        >
          <div
            className={`text-center font-weight-bold mt-5 font-${md ? 20 : 13}`}
          >
            <Text>ポイント購入ページを開きます </Text>
            <br />
            <Text>よろしいですか？</Text>

            <div className="mt-6">
              <Item className="mb-0" valuePropName="checked">
                <Checkbox
                  checked={isHiddenNotification}
                  onChange={event =>
                    setIsHiddenNotification(event.target.checked)
                  }
                >
                  本日は表示しない
                </Checkbox>
              </Item>
            </div>
          </div>
        </ConfirmDialog>
      </div>
    </>
  );
};

export default memo(PointExchangeScreen);
