import { memo } from "react";

import { Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";

import CardImg from "@app/assets/images/card.png";
import BankImg from "@app/assets/images/startupGuide/bank.png";
import ByPoint from "@app/assets/images/startupGuide/byPoint.png";
import CardArrowImg from "@app/assets/images/startupGuide/card-arrow.png";
import CardPayment from "@app/assets/images/startupGuide/card.png";
import cardGroup from "@app/assets/images/startupGuide/cardGroup.png";
import ContainerImg from "@app/assets/images/startupGuide/container.png";
import GachaImg from "@app/assets/images/startupGuide/gacha.png";
import PackImg from "@app/assets/images/startupGuide/pack.png";
import ShopImg from "@app/assets/images/startupGuide/shop.png";
import Button from "@app/components/atoms/Button/Button";

import GuideTitle from "../../components/GuideTitle/GuideTitle";
import styles from "./StartupGuideScreen.module.scss";

const StartupGuideScreen = () => {
  const { md } = useBreakpoint();
  return (
    <div className={cx(styles.root)}>
      <div className={cx(styles.container)}>
        <section className="text-center px-5">
          <GuideTitle className="pt-5" title="How To Play" subTitle="遊び方" />
          <section className={cx(styles.thumb)}>
            <div className={cx(styles.thumbBg)} />
            <div className={cx(styles.thumbContent)}>
              <img className={cx(styles.cardImg)} src={CardImg} alt="" />
              <div
                style={{
                  minWidth: md ? 300 : 225,
                }}
                className={cx(
                  styles.thumbText,
                  "ml-1",
                  md ? "pt-10 text-left ml-7" : ""
                )}
              >
                <div className={cx(styles.tipBox)}>
                  <span className={cx(md ? "mr-4" : "mr-2")}>
                    平日も休日も◎
                  </span>
                  <span>朝も深夜も◎</span>
                </div>
                <Typography
                  className={cx(
                    "text-white font-weight-bold text-left",
                    md ? "font-24 mt-8" : "font-14 mt-2"
                  )}
                >
                  トレビルのガチャは
                </Typography>
                <Typography
                  className={cx(styles.content, md ? "mt-4" : "mt-2")}
                >
                  24時間いつでも引ける！
                </Typography>
              </div>
            </div>
          </section>

          <GuideTitle
            className="mt-10"
            title="STEP 01"
            subTitle="まずはポイント購入！"
          />
          <Typography
            className={cx(
              "font-weight-medium",
              md ? "font-15 mt-11" : "font-11 mt-6"
            )}
          >
            マイページ ＞ ポイント購入から
            <br />
            お好きなポイントを購入
          </Typography>
          <div className={cx(md ? "mt-10" : "mt-4")}>
            <img
              src={ByPoint}
              width="100%"
              style={{
                maxWidth: md ? "455px" : "333px",
              }}
              alt=""
            />
          </div>
          <Typography
            className={cx(
              "font-weight-medium mt-6",
              md ? "font-15" : "font-11"
            )}
          >
            ※ポイントは500～300,000ポイントまで選択可能です。
          </Typography>

          <div className={cx(styles.cardBox, "mx-auto mt-6")}>
            <Typography
              className={cx(
                md ? "mt-10 font-18" : "font-13",
                "font-weight-bold pt-5"
              )}
            >
              ポイント購入には、クレジットカード、
              <br />
              銀行振込でのお支払いが可能です。
            </Typography>
            <div className="flex-space-evenly mt-6">
              <div className={md ? "mt-4" : ""}>
                <img width={md ? "154px" : "85px"} src={CardPayment} alt="" />
                <Typography
                  className={cx(
                    md ? "mt-2 font-15" : "font-13 pt-1",
                    "font-weight-medium"
                  )}
                >
                  クレジットカード
                </Typography>
              </div>
              <div>
                <img width={md ? "129px" : "63px"} src={BankImg} alt="" />
                <Typography
                  className={cx(
                    md ? "mt-2 font-15" : "font-13 pt-1",
                    "font-weight-medium"
                  )}
                >
                  銀行振込
                </Typography>
              </div>
            </div>
          </div>

          <GuideTitle
            className="mt-25"
            title="STEP 02"
            subTitle="お好みのガチャを回そう！"
          />

          <div className={cx(md ? "flex-justify-center gap-40" : "")}>
            <div>
              <div
                className={cx(
                  "mt-8 mb-2",
                  md ? "flex-justify-center" : "",
                  "flex-align-center"
                )}
              >
                <div className={cx(styles.number, "flex-center")}>01</div>
                <span
                  className={cx(
                    md ? "font-20" : "font-13",
                    "font-weight-medium"
                  )}
                >
                  気になる店舗をタップして
                </span>
              </div>
              <div className="text-center">
                <img
                  width="100%"
                  style={{
                    maxWidth: 202,
                  }}
                  src={ShopImg}
                  alt=""
                />
              </div>
            </div>

            <div>
              <div
                className={cx(
                  md ? "flex-justify-center" : "",
                  "flex-align-center mt-8 mb-2"
                )}
              >
                <div className={cx(styles.number, "flex-center")}>02</div>
                <span
                  className={cx(
                    md ? "font-20" : "font-13",
                    "font-weight-medium"
                  )}
                >
                  ガチャを選んで
                </span>
              </div>
              <img
                width="100%"
                style={{
                  maxWidth: 353,
                  marginTop: md ? 54 : 0,
                }}
                src={PackImg}
                alt=""
              />
            </div>

            <div>
              <div
                className={cx(
                  md ? "flex-justify-center" : "",
                  "flex-align-center mt-8 mb-2"
                )}
              >
                <div className={cx(styles.number, "flex-center")}>03</div>
                <span
                  className={cx(
                    md ? "font-20" : "font-13",
                    "font-weight-medium"
                  )}
                >
                  好きな回数を回そう！
                </span>
              </div>
              <img
                style={{
                  maxWidth: 337,
                  marginTop: md ? 94 : 0,
                }}
                width="100%"
                src={GachaImg}
                alt=""
              />
            </div>
          </div>

          <div className={cx(styles.cardBox, styles.cardBox3, "mt-6 mx-auto")}>
            <Typography
              className={cx(md ? "font-15" : "font-11", "font-weight-medium")}
            >
              ガチャは、それぞれに表示されている残り枚数分だけ
              引く事が出来ます。
              <br />
              ガチャは１回ずつと10回連続で引く機能があります。
              <br />
              ※ガチャを引く為には、ポイントが必要です。
            </Typography>
          </div>

          <section className={cx(styles.thumb, md ? "mt-15" : "mt-8")}>
            <div className={cx(styles.thumbBg, styles.thumbBg2)} />
            <div className={cx(md ? "flex-center" : "")}>
              <div
                style={{
                  minWidth: md ? 388 : 258,
                  textAlign: md ? "start" : "center",
                }}
              >
                <Typography
                  className={cx(
                    "pt-8 text-white font-weight-bold",
                    md ? "font-24" : "font-14"
                  )}
                >
                  カードの抽選は完全ランダム！
                </Typography>
                {md ? (
                  <>
                    <Typography className={cx(styles.content, "mt-2")}>
                      激レアカードを
                    </Typography>
                    <br />
                    <Typography className={cx(styles.content, "mt-2")}>
                      引き当てるチャンス！
                    </Typography>
                  </>
                ) : (
                  <Typography className={cx(styles.content, "mt-2")}>
                    激レアカードを引き当てるチャンス！
                  </Typography>
                )}
              </div>
              <div className="pb-5">
                <img
                  className="mt-5"
                  style={{
                    maxWidth: md ? 662 : 400,
                  }}
                  width="100%"
                  src={cardGroup}
                  alt=""
                />
              </div>
            </div>
          </section>

          <section className="mt-10">
            <GuideTitle title="STEP 03" subTitle="引き当てたカードは・・・" />
            {md ? (
              <Typography
                className={cx(
                  md ? "font-15" : "font-11",
                  "font-weight-medium mt-4"
                )}
              >
                引き当てたカードは、発送・またはポイント還元をする事が可能です。
              </Typography>
            ) : (
              <Typography
                className={cx(
                  md ? "font-15" : "font-11",
                  "font-weight-medium mt-4"
                )}
              >
                引き当てたカードは、
                <br />
                発送・またはポイント還元をする事が可能です。
              </Typography>
            )}
            <img
              className="mt-7"
              width={md ? "455px" : "226px"}
              src={CardArrowImg}
              alt=""
            />
            <div
              className={cx("flex-justify-center", md ? "gap-20" : "gap-10")}
            >
              <div className={cx(styles.boxContainer, styles.pointBox)}>
                <Typography
                  className={cx(
                    md ? "font-24" : "font-16",
                    "font-weight-bold text-white mt-3"
                  )}
                >
                  Pt変換
                </Typography>
                <div
                  className={cx(
                    "icon-point-icon mt-4",
                    md ? "font-72" : "font-36"
                  )}
                >
                  <span className="path1" />
                  <span className="path2" />
                </div>
                <Typography
                  className={cx(
                    md ? "font-15" : "font-10",
                    "font-weight-bold text-white mt-3"
                  )}
                >
                  {md ? (
                    <>不要なカードは ポイント還元して</>
                  ) : (
                    <>
                      不要なカードは
                      <br />
                      ポイント還元して
                    </>
                  )}
                  <br />
                  次のチャンスに活かせます！
                </Typography>
              </div>
              <div className={cx(styles.boxContainer, "mb-10")}>
                <Typography
                  className={cx(
                    md ? "font-24" : "font-16",
                    "font-weight-bold text-white mt-3"
                  )}
                >
                  発送
                </Typography>
                <img
                  className="mt-4"
                  width={md ? 114 : 58}
                  src={ContainerImg}
                  alt=""
                />
                <Typography
                  className={cx(
                    md ? "font-15" : "font-10",
                    "font-weight-bold text-white mt-3"
                  )}
                >
                  引いたガチャはいつでも 発送可能！
                  <br />
                  ５枚以上まとめて送ると 送料無料！
                </Typography>
              </div>
            </div>
          </section>
        </section>
      </div>
      <div className="text-center">
        <Button
          className={cx("gray", md ? "mt-8" : "mt-5 font-12")}
          to="/"
          shape="round"
          height={cx(md ? "56px" : "38px")}
          width={cx(md ? "255px" : "162px")}
        >
          ＴＯＰページへ戻る
        </Button>
      </div>
    </div>
  );
};

export default memo(StartupGuideScreen);
