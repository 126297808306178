export const STATUS_ON_SALE = 1;
export const STATUS_SOLD_OUT = 2;
export const STATUS_ALL = 3;

export const PACKS_LIST_STATUS: Record<number, string | null> = {
  [STATUS_ON_SALE]: "on_sale",
  [STATUS_SOLD_OUT]: "sold_out",
  [STATUS_ALL]: null,
};

export const CARD_TYPE = {
  BIG_HIT: "big_hit",
  MEDIUM_HIT: "medium_hit",
  SMALL_HIT: "small_hit",
  FAIL: "fail",
};

export const DELAY_AUTO_PLAY = 5000;

export const TIME_INTERVAL = 10000;

export const BANNERS_LIMIT = 10;

export const SHOP_PACK_LIMIT = 10;

export const SHOP_LIST_USER_LIMIT = 20;

export const MAX_CARD_SMALL_FAIL = 20;

export const MAX_CARD_MEDIUM_HIT_PC = 24;

export const MAX_CARD_MEDIUM_HIT_SP = 16;

export const TIME_AUTO_EXCHANGE_CARD = 14;
export const TIME_SHOW_ALERT_EXCHANGE_CARD = 3;
