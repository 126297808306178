import { memo } from "react";

import { Col, Row } from "antd";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

import OripaCard from "../OripaCard/OripaCard";
import styles from "./ListOripaCard.module.scss";

interface ListOripaCardProps {
  isLoading: boolean;
  fetchMoreData: () => Promise<void>;
}

const ListOripaCard = ({ isLoading, fetchMoreData }: ListOripaCardProps) => {
  const { shopPacks } = useAppSelector((state: RootState) => state.shops);

  return (
    <div className={cx(styles.root)}>
      {!isLoading ? (
        <>
          {shopPacks?.packs?.length ? (
            <InfiniteScroll
              dataLength={shopPacks.packs.length ?? 0}
              next={fetchMoreData}
              hasMore={shopPacks.packs.length < shopPacks.page_info.total_count}
              loader={<LoadingSpinner />}
            >
              <Row
                style={{
                  paddingBottom: "20px",
                }}
                gutter={[{ xs: 0, sm: 18, md: 24 }, 24]}
              >
                {shopPacks?.packs?.map(pack => (
                  <Col xs={24} sm={12} md={12} key={pack.id}>
                    <OripaCard pack={pack} />
                  </Col>
                ))}
              </Row>
            </InfiniteScroll>
          ) : (
            <div className={cx(styles.noData)}>検索結果がありません。</div>
          )}
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default memo(ListOripaCard);
