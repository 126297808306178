import { unwrapResult } from "@reduxjs/toolkit";
import { useHistory, useParams } from "react-router-dom";

import { openNotification } from "@app/components/molecules/Notification/notification";
import { ERROR_MESSAGES } from "@app/constants/message.constants";
import { getUrlVideo } from "@app/helpers/util.helper";
import { useAppDispatch } from "@app/redux/store";

import {
  CARD_STATUS,
  GachaHistoriesPathsEnum,
  GACHA_STATUS,
  GACHA_TIMEOUT,
  getGachaHistoriesDetail,
} from "../../gacha-histories";

const useGetGachaHistories = (
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { shopId, packId, gachaId } = useParams<{
    gachaId: string;
    packId: string;
    shopId: string;
  }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getGachaHistories = ({
    isGacha,
    id,
  }: {
    isGacha: boolean;
    id?: string;
  }) => {
    const data = {
      id: id ?? gachaId,
      params: {
        card_statuses: [CARD_STATUS.SOLD],
      },
    };

    const redirectToGachaHistory = (url: string) => {
      history.push({
        pathname: GachaHistoriesPathsEnum.RESULTS_PAGE.replace(
          ":shopId",
          shopId
        )
          .replace(":packId", packId)
          .replace(":gachaId", data.id),
        state: {
          urlAnimation: url,
        },
      });
    };

    dispatch(getGachaHistoriesDetail(data))
      .then(unwrapResult)
      .then(result => {
        if (result.status === GACHA_STATUS.FAIL) {
          setIsSubmitting(false);
          openNotification({
            type: "error",
            message: ERROR_MESSAGES.UA_148,
          });
        }
        if (result.status === GACHA_STATUS.IN_PROGRESS) {
          setTimeout(() => {
            getGachaHistories({
              isGacha,
              id,
            });
          }, GACHA_TIMEOUT);
        }
        if (result.status === GACHA_STATUS.COMPLETED) {
          const videoUrl = getUrlVideo(result);
          if (!isGacha || !videoUrl) return;

          const isIosPlatform =
            typeof navigator !== "undefined" &&
            (/iPad|iPhone|iPod/.test(navigator.userAgent || "") ||
              (navigator.platform === "MacIntel" &&
                navigator.maxTouchPoints > 1));

          if (isIosPlatform) {
            redirectToGachaHistory(videoUrl);
            return;
          }

          fetch(videoUrl)
            .then(res => res.blob())
            .then(b => URL.createObjectURL(b))
            .then(url => redirectToGachaHistory(url))
            .catch(() => redirectToGachaHistory(videoUrl));
        }
      });
  };
  return [getGachaHistories];
};

export default useGetGachaHistories;
