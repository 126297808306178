/* eslint-disable no-useless-escape */
export const DURATION_NOTIFICATION = 5;

export const PAGINATION_LIMIT_PC = 8;

export const SHOP_LIMIT_SP = 6;

export const SHOP_PACKS_LIMIT_SP = 5;

export const PASSWORD_REGEX =
  /^[A-Za-z\d@$!%*#?&\/\\\"{}|;<>[\]`+~=_,^()-.\:\']*$/g;

export const PHONE_REGEX = /^(?=.*[\d])[\d]{12}$/g;

export const MODE_ACTIONS = {
  CREATE: "create",
  EDIT: "edit",
};

export const LOCAL_STORAGE_BACK_PAGE_KEY = "backPage";

export const NUMBER_ROLL_GACHA = {
  ONE: 1,
  TEN: 10,
};

export const PAGE_INFO = {
  "UA-0001": {
    title: "オリパオンラインガチャ｜ポケモントレカ通販のオリパ｜トレビル",
    description: "",
  },
  "UA-0002": {
    title: "ポケカ通販おすすめオリパ｜トレビル",
    description:
      "のオリジナルカードパックでポケカゲット！不要カードはポイント変換してリトライ可能。準備中のワンピース他、人気カードを随時追加致します。",
  },
  "UA-0003": {
    title: "",
    description:
      "のオリジナルカードパックでポケカゲット！不要カードはポイント変換してリトライ可能。準備中のワンピース他、人気カードを随時追加致します。",
  },
  "UA-0004": {
    title: "",
    description:
      "のオリジナルカードパックでポケカゲット！不要カードはポイント変換してリトライ可能。準備中のワンピース他、人気カードを随時追加致します。",
  },
  "UA-0005": {
    title: "結果｜トレビル",
    description:
      "のオリジナルカードパックでポケカゲット！不要カードはポイント変換してリトライ可能。準備中のワンピース他、人気カードを随時追加致します。",
  },
  "UA-0006": {
    title: "発送先一覧｜トレビル",
    description: "",
  },
  "UA-0007": {
    title: "発送先入力｜トレビル",
    description: "",
  },
  "UA-0008": {
    title: "ポイント購入｜トレビル",
    description: "",
  },
  "UA-0011": {
    title: "獲得カード所持履歴｜トレビル",
    description: "",
  },
  "UA-0012": {
    title: "獲得カード所持詳細｜トレビル",
    description: "",
  },
  "UA-0013": {
    title: "マイページ｜トレビル",
    description: "",
  },
  "UA-0014": {
    title: "ログイン｜トレビル",
    description: "",
  },
  "UA-0015": {
    title: "パスワード再設定｜トレビル",
    description: "",
  },
  "UA-0016": {
    title: "パスワード再設定メール送信完了｜トレビル",
    description: "",
  },
  "UA-0017": {
    title: "仮登録画面｜トレビル",
    description: "",
  },
  "UA-0018": {
    title: "仮登録完了｜トレビル",
    description: "",
  },
  "UA-0019": {
    title: "新規会員本登録｜トレビル",
    description: "",
  },
  "UA-0020": {
    title: "新規会員本登録_確認｜トレビル",
    description: "",
  },
  "UA-0021": {
    title: "新規会員本登録_完了｜トレビル",
    description: "",
  },
  "UA-0023": {
    title: "仮登録期限切れ画面｜トレビル",
    description: "",
  },
  "UA-0024": {
    title: "パスワード再設定入力｜トレビル",
    description: "",
  },
  "UA-0025": {
    title: "パスワード再設定完了｜トレビル",
    description: "",
  },
  "UA-0026": {
    title: "決済方法選択｜トレビル",
    description: "",
  },
  "UA-0027": {
    title: "決済方法選択完了｜トレビル",
    description: "",
  },
  "UA-0028": {
    title: "振込確認｜トレビル",
    description: "",
  },
  "UA-0029": {
    title: "振込完了｜トレビル",
    description: "",
  },
  "UA-0050": {
    title: "初めての方へ｜トレビル",
    description: "",
  },
  "UA-0051": {
    title: "初めての方へ｜トレビル",
    description: "",
  },
  "UA-0052": {
    title: "初めての方へ｜トレビル",
    description: "",
  },
  "UA-0053": {
    title: "初めての方へ｜トレビル",
    description: "",
  },
};
