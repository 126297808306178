/* eslint-disable no-irregular-whitespace */
import { memo, useEffect } from "react";

import { Tabs } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory, useParams } from "react-router-dom";

import Helmet from "@app/components/molecules/Helmet/Helmet";
import { AuthPathsEnum } from "@app/features/auth/auth";
import { CARD_STATUS } from "@app/features/gacha-histories/gacha-histories";
import {
  getQueryParams,
  setQueryParams,
} from "@app/helpers/queryParams/queryParams";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import ListGachaCard from "../../components/ListGachaCard/ListGachaCard";
import ShopDetailsLayout from "../../components/ShopDetailsLayout/ShopDetailsLayout";
import { getShopDetail, ShopsPathsEnum } from "../../shops";
import styles from "./ShopListUserDetailsScreen.module.scss";

const ShopListUserDetailsScreen = () => {
  const { id } = useParams<{ id: string }>();
  const { stt } = getQueryParams(window.location.search);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { md } = useBreakpoint();

  const { isGettingCards } = useAppSelector((state: RootState) => state.shops);

  useEffect(() => {
    if (id) {
      dispatch(getShopDetail(id));
    }
  }, [dispatch, id]);

  if (
    ![CARD_STATUS.SOLD, CARD_STATUS.WAIT_FOR_SEND, CARD_STATUS.SENT].includes(
      String(stt)
    )
  ) {
    history.push(AuthPathsEnum.NOT_FOUND);
  }
  const { shopDetail } = useAppSelector((state: RootState) => state.shops);

  return (
    <ShopDetailsLayout>
      <Helmet title={shopDetail?.name} />
      <div className={`font-weight-bold container pb-6 font-${md ? 15 : 11}`}>
        ※獲得カードの保有期間は2週間となります。2週間以内にPOINT変換又は発送連絡をお願いいたします。
        <br />
        　２週間を超えたカードは自動的にPOINTへ変換されますのであらかじめご了承ください。
      </div>
      <Tabs
        className={cx(styles.tabs)}
        onChange={activeKey => {
          history.push({
            pathname: ShopsPathsEnum.SHOP_LIST_USER_DETAILS.replace(":id", id),
            search: setQueryParams({
              stt: activeKey,
            }),
          });
        }}
        defaultActiveKey={stt ? String(stt) : CARD_STATUS.SOLD}
      >
        <Tabs.TabPane
          disabled={isGettingCards}
          tab="未選択"
          key={CARD_STATUS.SOLD}
        >
          <ListGachaCard />
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={isGettingCards}
          tab="発送待ち"
          key={CARD_STATUS.WAIT_FOR_SEND}
        >
          <ListGachaCard />
        </Tabs.TabPane>
        <Tabs.TabPane
          disabled={isGettingCards}
          tab="発送済み"
          key={CARD_STATUS.SENT}
        >
          <ListGachaCard />
        </Tabs.TabPane>
      </Tabs>
    </ShopDetailsLayout>
  );
};

export default memo(ShopListUserDetailsScreen);
