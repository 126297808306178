import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";

import { AuthPathsEnum } from "@app/features/auth/auth";

import { Card } from "../../shops/types/shops.types";
import gachaHistoriesApi from "../api/gacha-histories.api";
import {
  GachaHistoriesDataDef,
  GACHA_HISTORIES_KEY,
  GACHA_STATUS,
} from "../gacha-histories";
import {
  ExchangeCardDataDef,
  GachaHistories,
  ParamsGachaHistoriesDetail,
} from "../types/gacha-histories.type";

interface initialStateType {
  gachaHistories: GachaHistories | null;
  gachaHistoriesChecked: Card[];
  numberRollPack: number | null;
}

const initialState: initialStateType = {
  gachaHistories: null,
  gachaHistoriesChecked: [],
  numberRollPack: null,
};

export const postGachaHistories = createAsyncThunk(
  `${GACHA_HISTORIES_KEY}/postGachaHistories`,
  async (data: GachaHistoriesDataDef, { rejectWithValue }) => {
    try {
      const response = await gachaHistoriesApi.postGachaHistoriesApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getGachaHistoriesDetail = createAsyncThunk(
  `${GACHA_HISTORIES_KEY}/getGachaHistoriesDetail`,
  async (
    { id, params }: { id: string; params: ParamsGachaHistoriesDetail },
    { rejectWithValue }
  ) => {
    try {
      const response = await gachaHistoriesApi.getGachaHistoriesDetailApi({
        id,
        params,
      });
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const exchangeCardToPoint = createAsyncThunk(
  `${GACHA_HISTORIES_KEY}/exchangeCardToPoint`,
  async (data: ExchangeCardDataDef, { rejectWithValue }) => {
    try {
      const response = await gachaHistoriesApi.exchangeCardToPointApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getPackNumberRollUser = createAsyncThunk(
  `${GACHA_HISTORIES_KEY}/getPackNumberRollUser`,
  async (
    {
      pack_id,
    }: {
      pack_id: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await gachaHistoriesApi.getPackNumberRollUserApi({
        pack_id,
      });
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const gachaHistoriesSlice = createSlice({
  name: GACHA_HISTORIES_KEY,
  initialState,
  reducers: {
    setGachaHistoriesChecked: (state, action) => {
      const currentGacha = action.payload;
      const gachaList = state.gachaHistoriesChecked;
      const indexGacha = gachaList.findIndex(
        item => item.id === currentGacha.id
      );
      if (indexGacha < 0) {
        gachaList.push(currentGacha);
      } else {
        gachaList.splice(indexGacha, 1);
      }
      state.gachaHistoriesChecked = gachaList;
    },
    cleanGachaHistoriesChecked: state => {
      state.gachaHistoriesChecked = [];
    },
    selectAllGachaHistories: (state, action) => {
      state.gachaHistoriesChecked = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getGachaHistoriesDetail.fulfilled, (state, actions) => {
      if (isEmpty(actions.payload)) {
        window.location.href = AuthPathsEnum.NOT_FOUND;
      }
      if (actions.payload.status === GACHA_STATUS.COMPLETED) {
        state.gachaHistories = actions.payload;
      }
    });
    builder.addCase(getGachaHistoriesDetail.rejected, state => {
      state.gachaHistories = null;
    });

    builder.addCase(getPackNumberRollUser.pending, state => {
      state.numberRollPack = null;
    });
    builder.addCase(getPackNumberRollUser.fulfilled, (state, actions) => {
      state.numberRollPack = actions.payload.number_roll;
    });
    builder.addCase(getPackNumberRollUser.rejected, state => {
      state.numberRollPack = null;
    });
  },
});

export const {
  setGachaHistoriesChecked,
  cleanGachaHistoriesChecked,
  selectAllGachaHistories,
} = gachaHistoriesSlice.actions;

export const gachaHistoriesReducer = gachaHistoriesSlice.reducer;
