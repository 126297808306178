import { lazy, Suspense, useEffect } from "react";

import { useMount } from "ahooks";
import { BrowserRouter as Router } from "react-router-dom";

import "@app/helpers/dayjs/dayjs.helper";
import { getCurrentUser, getListSettings } from "./features/auth/auth";
import store, { useAppDispatch } from "./redux/store";

// Routes are lazy loaded so they will access to correct permissions
const Routes = lazy(() => import("./routes/Routes"));

const App = () => {
  const dispatch = useAppDispatch();

  useMount(() => {
    const accessToken = !!store.getState().auth.accessToken;
    dispatch(getListSettings());
    if (accessToken) {
      dispatch(getCurrentUser());
    }
  });

  // cheat unlock audio
  useEffect(() => {
    const unlockAudio = () => {
      const sound = new Audio("/sound.mp3");

      sound.play();
      sound.pause();
      sound.currentTime = 0;

      document.body.removeEventListener("click", unlockAudio);
      document.body.removeEventListener("touchstart", unlockAudio);
    };

    document.body.addEventListener("click", unlockAudio);
    document.body.addEventListener("touchstart", unlockAudio);
  });

  return (
    <Suspense fallback={<></>}>
      <Router>
        <Routes />
      </Router>
    </Suspense>
  );
};

export default App;
