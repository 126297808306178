import { memo, useEffect, useMemo, useState } from "react";

import { Modal, ModalProps } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory, useParams } from "react-router-dom";

import Button from "@app/components/atoms/Button/Button";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import { openNotification } from "@app/components/molecules/Notification/notification";
import GachaCard from "@app/features/gacha-histories/components/GachaCard/GachaCard";
import { cleanGachaHistoriesChecked } from "@app/features/gacha-histories/redux/gacha-histories.slice";
import CardAddress from "@app/features/user-addresses/components/CardAddress/CardAddress";
import { getUserAddressList } from "@app/features/user-addresses/redux/user-addresses.slice";
import { UserAddressesPathsEnum } from "@app/features/user-addresses/user-addresses";
import { convertPhoneNumber, getUserAddress } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import { cardToDelivery, ShopsPathsEnum } from "../../shops";
import styles from "./OrderDialog.module.scss";

interface OrderDialogProps extends ModalProps {
  open: boolean;
  handleCancel?: () => void;
  hasButtonBack?: boolean;
}

const OrderDialog = ({
  open,
  handleCancel,
  hasButtonBack,
  ...rest
}: OrderDialogProps) => {
  const history = useHistory();
  const { id, shopId } = useParams<{ id: string; shopId: string }>();
  const [isPageSelectAddress, setIsPageSelectAddress] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [handleReload, setHandleReload] = useState<boolean>(false);
  const { md } = useBreakpoint();
  const dispatch = useAppDispatch();
  const {
    user_addresses: { userAddressList },
    gacha_histories: { gachaHistoriesChecked },
  } = useAppSelector((state: RootState) => state);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getUserAddressList()).finally(() => setIsLoading(false));
  }, [dispatch, handleReload]);

  const userAddressDefault = useMemo(
    () => userAddressList?.user_addresses.find(item => item.is_default),
    [userAddressList?.user_addresses]
  );

  const handleSubmit = () => {
    setIsSubmitting(true);
    dispatch(
      cardToDelivery({
        card_ids: gachaHistoriesChecked.map(item => item.id),
        user_address_id: Number(userAddressDefault?.id),
      })
    )
      .then(() => {
        handleCancel?.();
        dispatch(cleanGachaHistoriesChecked());
        history.push(
          ShopsPathsEnum.DELIVERY_SUCCESS.replace(":id", id ?? shopId),
          { path: hasButtonBack ? history.location.pathname : undefined }
        );
      })
      .catch(err => {
        openNotification({ type: "warning", message: err.errors });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Modal
      open={open}
      title={isPageSelectAddress ? "発送先住所の選択" : "内容確認"}
      width={md ? 886 : 333}
      centered
      onOk={handleSubmit}
      onCancel={handleCancel}
      maskClosable={false}
      className={cx(styles.dialog)}
      closeIcon={<span className="icon-close-icon font-18" />}
      footer={[
        <Button
          type={!isPageSelectAddress ? "primary" : undefined}
          borderRadius="26px"
          withLeftArrow={!isPageSelectAddress}
          onClick={() => {
            isPageSelectAddress
              ? handleCancel?.()
              : setIsPageSelectAddress(true);
          }}
          className={cx(styles.btn, isPageSelectAddress ? "gray" : undefined)}
        >
          {isPageSelectAddress ? "キャンセル" : "戻る"}
        </Button>,
        <Button
          type="primary"
          danger
          withRightArrow
          borderRadius="26px"
          disabled={
            isPageSelectAddress
              ? !userAddressList?.user_addresses.length
              : isSubmitting
          }
          onClick={() => {
            isPageSelectAddress
              ? setIsPageSelectAddress(false)
              : handleSubmit?.();
          }}
          className={styles.btn}
        >
          {isPageSelectAddress ? "次へ" : "注文確定"}
        </Button>,
      ]}
      {...rest}
    >
      <div
        className={cx(
          md ? "font-20" : "font-13",
          "font-weight-bold text-center"
        )}
      >
        {isPageSelectAddress
          ? "発送先住所をお選びください"
          : "こちらの内容でよろしいでしょうか？"}
      </div>
      {isPageSelectAddress && !!userAddressList?.user_addresses.length && (
        <div
          className={cx(
            md ? "font-15 mt-3" : "font-12 mt-2",
            "text-center font-weight-medium px-5"
          )}
        >
          <p>
            <span>メイン住所を変更する際には</span>
            <span
              className={cx("icon-check-icon mx-1", md ? "font-24" : "font-18")}
            />
            <span>マークをタップしてください。</span>
          </p>
        </div>
      )}
      {isPageSelectAddress ? (
        <>
          <div
            className={cx(
              "flex-justify-center flex-wrap",
              styles.cardBox,
              md ? "mt-6" : "mt-3"
            )}
          >
            {!isLoading ? (
              <>
                {userAddressList?.user_addresses.length ? (
                  <>
                    {userAddressList?.user_addresses.map(userAddress => (
                      <div className={cx(styles.card)}>
                        <CardAddress
                          isCardInOrderDialog
                          userAddress={userAddress}
                          handleReload={() => setHandleReload(!handleReload)}
                        />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className={cx(styles.noCardAddressBox)}>
                    <div className={cx(styles.noCardAddress)}>
                      <div
                        className={md ? "mb-4 ml-8 font-15" : "ml-4 font-11"}
                      >
                        登録済み住所がありません。
                      </div>
                    </div>
                  </div>
                )}
                <br />
                <div
                  className={cx(
                    styles.noCardAddressBox,
                    // eslint-disable-next-line no-nested-ternary
                    !userAddressList?.user_addresses.length
                      ? md
                        ? "ml-15"
                        : "ml-7"
                      : ""
                  )}
                >
                  <div
                    className={cx(
                      "flex-align-center cursor-pointer",
                      styles.noCardAddress
                    )}
                    aria-hidden
                    onClick={() =>
                      history.push(UserAddressesPathsEnum.LIST_USER_ADDRESSES)
                    }
                  >
                    <span className="icon-add-icon" />
                    <div
                      className={cx(
                        "text-green",
                        md ? "ml-3 font-20" : "ml-2 font-14"
                      )}
                    >
                      新しい住所を追加
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </>
      ) : (
        <div className={cx(styles.secondPageBox)}>
          <div className={cx(styles.secondPage)}>
            <div className={cx(styles.title)}>ご購入商品</div>
            <div className="flex-justify-center">
              <div className={cx(styles.gachaCardBox)}>
                {gachaHistoriesChecked.map(gachaCard => (
                  <GachaCard
                    card={gachaCard}
                    isGachaCardInOrderDialog
                    classNameCard={cx(styles.cardPC)}
                    classNameCardIcon={cx(styles.cardIcon)}
                  />
                ))}
              </div>
            </div>
            <div className={cx(styles.divider)} />
            <div className={cx(styles.addressInfo)}>
              <div className={cx(styles.title)}>お届け先住所</div>
              <div className={cx(styles.cardTitle)}>{`${
                userAddressDefault?.firstname ?? "-"
              }  ${userAddressDefault?.lastname ?? ""}`}</div>
              <div className="mb-4">
                <div className={cx(styles.titleWithDots)}>ご住所</div>
                <div>
                  {userAddressDefault?.zipcode &&
                    `〒${convertPhoneNumber(userAddressDefault?.zipcode)}`}
                </div>
                {getUserAddress(userAddressDefault)}
              </div>
              <div className={cx(styles.titleWithDots)}>電話番号</div>
              {convertPhoneNumber(userAddressDefault?.tel ?? 0)}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default memo(OrderDialog);
