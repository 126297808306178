import { memo, useMemo, useState } from "react";

import { Modal } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { GlassMagnifier } from "react-image-magnifiers";

import NoImage from "@app/assets/images/no_image_card.png";
import ButtonArrow from "@app/components/atoms/ButtonArrow/ButtonArrow";
import Image from "@app/components/atoms/Image/Image";
import SpinWrapper from "@app/components/atoms/SpinWrapper/SpinWrapper";
import { getNameCard } from "@app/helpers/util.helper";

import { CARD_TYPE } from "../../shops";
import { Card } from "../../types/shops.types";
import StatusPack from "../StatusPack/StatusPack";
import styles from "./ZoomDialog.module.scss";

interface ZoomDialogProps {
  isOpenDialog: boolean;
  handleCancel: () => void;
  card: Card;
}
const ZoomDialog = ({ isOpenDialog, handleCancel, card }: ZoomDialogProps) => {
  const { md } = useBreakpoint();
  const [isFrontCardSelected, setIsFrontCardSelected] = useState<boolean>(true);
  const [isLoadingImage, setIsLoadingImage] = useState(true);
  const [isLoadingImageLarge, setIsLoadingImageLarge] = useState(true);

  const { frontCard, backCard } = useMemo(() => {
    return {
      frontCard:
        card.card_images.find(item => !!item.is_primary)?.image_url ?? "",
      backCard:
        card.card_images.find(item => !item.is_primary)?.image_url ?? "",
    };
  }, [card.card_images]);

  const ImageMagnifier = () => {
    if (!frontCard && !backCard) {
      return <img src={NoImage} className={cx(styles.cardNoImg)} alt="img" />;
    }
    return (
      <SpinWrapper loading={isLoadingImage || isLoadingImageLarge}>
        <GlassMagnifier
          imageSrc={isFrontCardSelected ? frontCard ?? backCard : backCard}
          onImageLoad={() => {
            setIsLoadingImage(false);
          }}
          onLargeImageLoad={() => {
            setIsLoadingImageLarge(false);
          }}
          magnifierSize={md ? "65.5%" : "145%"}
          allowOverflow
          magnifierBorderSize={0}
          className={cx(styles.cardZoom, {
            [styles.hidden]: isLoadingImage || isLoadingImageLarge,
          })}
        />
      </SpinWrapper>
    );
  };

  const closeDialog = () => {
    handleCancel();
    setIsFrontCardSelected(true);
    setIsLoadingImage(true);
    setIsLoadingImageLarge(true);
  };

  return (
    <Modal
      open={isOpenDialog}
      key={card.id}
      onCancel={closeDialog}
      footer={null}
      centered
      maskClosable={false}
      className={cx(styles.dialog)}
      maskStyle={{ height: "150vh" }}
      width={md ? 545 : 333}
      closeIcon={<span className="icon-close-icon" />}
    >
      <div key={card.id}>
        <div className={cx(styles.title)}>
          {getNameCard(card, card.type === CARD_TYPE.BIG_HIT)}
        </div>
        <div className={cx(styles.statusBox)}>
          {!!card.quality && (
            <StatusPack
              text={card.quality?.name}
              className={cx(styles.statusTag)}
            />
          )}
        </div>
        <div
          className={cx(
            styles.cardZoomBox,
            (!backCard || !frontCard) && styles.cardCenter
          )}
        >
          {frontCard && backCard && (
            <ButtonArrow
              direction="left"
              onClick={() => {
                setIsLoadingImage(true);
                setIsLoadingImageLarge(true);
                setIsFrontCardSelected(!isFrontCardSelected);
              }}
            />
          )}
          <ImageMagnifier />
          {frontCard && backCard && (
            <ButtonArrow
              direction="right"
              onClick={() => {
                setIsLoadingImage(true);
                setIsLoadingImageLarge(true);
                setIsFrontCardSelected(!isFrontCardSelected);
              }}
            />
          )}
        </div>
        <div className={cx(styles.cardBox)}>
          {frontCard && (
            <div
              className={cx(styles.card)}
              onClick={() => {
                setIsLoadingImage(true);
                setIsLoadingImageLarge(true);
                setIsFrontCardSelected(true);
              }}
              aria-hidden
            >
              <Image src={frontCard} className={cx(styles.cardImg)} />
              {backCard && !isFrontCardSelected && (
                <div className={cx(styles.noSelected)} />
              )}
            </div>
          )}
          {backCard && (
            <div
              className={cx(styles.card)}
              onClick={() => {
                setIsLoadingImage(true);
                setIsLoadingImageLarge(true);
                setIsFrontCardSelected(false);
              }}
              aria-hidden
            >
              <Image src={backCard} className={cx(styles.cardImg)} />
              {frontCard && isFrontCardSelected && (
                <div className={cx(styles.noSelected)} />
              )}
            </div>
          )}
          {!frontCard && !backCard && (
            <Image src={NoImage} className={cx(styles.card)} />
          )}
        </div>
        <div className={md ? "font-14" : "font-12"}>
          {card.description ?? ""}
        </div>
      </div>
    </Modal>
  );
};

export default memo(ZoomDialog);
