import BlankLayout from "@app/components/layouts/BlankLayout/BlankLayout";
import MaintenanceLayout from "@app/components/layouts/MaintenanceLayout/MaintenanceLayout";
import { PAGE_INFO } from "@app/constants/constants";
import NotFound from "@app/routes/components/NotFound/NotFound";
import { RouteItemDef } from "@app/types/route.types";

import { AuthPathsEnum } from "../constants/auth.paths";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen/ForgotPasswordScreen";
import ForgotPasswordSuccessScreen from "../screens/ForgotPasswordSuccessScreen/ForgotPasswordSuccessScreen";
import LoginScreen from "../screens/LoginScreen/LoginScreen";
import MaintenanceScreen from "../screens/MaintenanceScreen/MaintenanceScreen";
import MyPageScreen from "../screens/MyPageScreen/MyPageScreen";
import RegisterOfficialMemberScreen from "../screens/RegisterOfficialMemberScreen/RegisterOfficialMemberScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen/ResetPasswordScreen";
import Template from "../screens/TemplateScreen/Template";
import TemporaryRegisterScreen from "../screens/TemporaryRegisterScreen/TemporaryRegisterScreen";
import TemporaryRegisterSuccessScreen from "../screens/TemporaryRegisterSuccessScreen/TemporaryRegisterSuccessScreen";
import TokenExpirationScreen from "../screens/TokenExpirationScreen/TokenExpirationScreen";
import VerifyUserSuccessEmailScreen from "../screens/VerifyUserSuccessEmailScreen/VerifyUserSuccessEmailScreen";

const TEMPLATE_SCREEN: RouteItemDef = {
  id: "template",
  path: AuthPathsEnum.TEMPLATE,
  component: Template,
  navigationTitle: "auth.template",
  layout: BlankLayout,
};

const LOGIN_SCREEN: RouteItemDef = {
  id: "login",
  path: AuthPathsEnum.LOGIN,
  component: LoginScreen,
  navigationTitle: "auth.login",
  layout: BlankLayout,
  isAuthRoute: true,
  pageTitle: "ログイン｜トレビル",
};

const CREATE_USER_SCREEN: RouteItemDef = {
  id: "UA-0019",
  path: AuthPathsEnum.CREATE_USER,
  layout: BlankLayout,
  component: RegisterOfficialMemberScreen,
  pageTitle: PAGE_INFO["UA-0019"].title,
};

const TOKEN_EXPIRATION_SCREEN: RouteItemDef = {
  id: "UA-0023",
  path: AuthPathsEnum.TOKEN_EXPIRATION,
  layout: BlankLayout,
  component: TokenExpirationScreen,
  pageTitle: PAGE_INFO["UA-0023"].title,
};

const FORGOT_PASSWORD_SCREEN: RouteItemDef = {
  id: "UA-0015",
  path: AuthPathsEnum.FORGOT_PASSWORD,
  component: ForgotPasswordScreen,
  layout: BlankLayout,
  isAuthRoute: true,
  pageTitle: PAGE_INFO["UA-0015"].title,
};

const FORGOT_PASSWORD_SUCCESS_SCREEN: RouteItemDef = {
  id: "UA-0016",
  path: AuthPathsEnum.FORGOT_PASSWORD_SUCCESS,
  component: ForgotPasswordSuccessScreen,
  layout: BlankLayout,
  isAuthRoute: true,
  pageTitle: PAGE_INFO["UA-0016"].title,
};

const TEMPORAPY_REGISTER_SCREEN: RouteItemDef = {
  id: "UA-0017",
  path: AuthPathsEnum.TEMPORAPY_REGISTER,
  component: TemporaryRegisterScreen,
  layout: BlankLayout,
  isAuthRoute: true,
  pageTitle: PAGE_INFO["UA-0017"].title,
};

const TEMPORAPY_REGISTER_SUCCESS_SCREEN: RouteItemDef = {
  id: "UA-0018",
  path: AuthPathsEnum.TEMPORAPY_REGISTER_SUCCESS,
  component: TemporaryRegisterSuccessScreen,
  layout: BlankLayout,
  isAuthRoute: true,
  pageTitle: PAGE_INFO["UA-0018"].title,
};

const RESET_PASSWORD_SCREEN: RouteItemDef = {
  id: "UA-0024",
  path: AuthPathsEnum.RESET_PASSWORD,
  component: ResetPasswordScreen,
  layout: BlankLayout,
  isAuthRoute: true,
  pageTitle: PAGE_INFO["UA-0024"].title,
};

const MY_PAGE_SCREEN: RouteItemDef = {
  id: "UA-0013",
  path: AuthPathsEnum.MY_PAGE,
  component: MyPageScreen,
  navigationTitle: "auth.myPage",
  withPadding: true,
  isPrivateRoute: true,
  pageTitle: PAGE_INFO["UA-0013"].title,
};

const NOT_FOUND: RouteItemDef = {
  id: "notFound",
  path: AuthPathsEnum.NOT_FOUND,
  component: NotFound,
  layout: BlankLayout,
};

const VERIFY_USER_SUCCESS_EMAIL_SCREEN: RouteItemDef = {
  id: "UA-0025",
  path: AuthPathsEnum.VERIFY_EMAIL,
  component: VerifyUserSuccessEmailScreen,
  layout: BlankLayout,
  pageTitle: PAGE_INFO["UA-0021"].title,
};

const MAINTENANCE_SCREEN: RouteItemDef = {
  id: "UA-0026",
  path: AuthPathsEnum.MAINTENANCE,
  component: MaintenanceScreen,
  layout: MaintenanceLayout,
  pageTitle: PAGE_INFO["UA-0026"].title,
};

export const AUTH_ROUTES = [
  TEMPLATE_SCREEN,
  LOGIN_SCREEN,
  CREATE_USER_SCREEN,
  TOKEN_EXPIRATION_SCREEN,
  FORGOT_PASSWORD_SCREEN,
  FORGOT_PASSWORD_SUCCESS_SCREEN,
  TEMPORAPY_REGISTER_SCREEN,
  TEMPORAPY_REGISTER_SUCCESS_SCREEN,
  RESET_PASSWORD_SCREEN,
  MY_PAGE_SCREEN,
  NOT_FOUND,
  VERIFY_USER_SUCCESS_EMAIL_SCREEN,
  MAINTENANCE_SCREEN,
];
