import { memo } from "react";

import { Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import { useHistory } from "react-router-dom";

import Logo from "@app/assets/images/logo.png";
import Logo2 from "@app/assets/images/logo2.png";
import Button from "@app/components/atoms/Button/Button";
import { ShopsPathsEnum } from "@app/features/shops/shops";

import styles from "./BankTransfer.module.scss";

interface BankTransferProps {
  title: string;
  content: string;
}

const BankTransfer = ({ title, content }: BankTransferProps) => {
  const { md } = useBreakpoint();
  const history = useHistory();

  return (
    <div className={cx(styles.root)}>
      <div className={cx(styles.container)}>
        <div className="container text-center">
          <section className={cx(md ? "pt-14" : "pt-9")}>
            <img
              alt=""
              className={cx(styles.logo)}
              src={md ? Logo : Logo2}
              width={md ? 514 : 210}
            />

            <Typography
              className={cx(
                "font-weight-bold",
                md ? "font-18 pt-11" : "font-13 pt-12"
              )}
            >
              {title}
            </Typography>

            <Typography
              className={cx(
                md ? "font-15  pt-6" : "font-11  pt-4",
                "font-weight-medium pre-wrap"
              )}
            >
              {content}
            </Typography>
          </section>
        </div>
      </div>
      <div className="text-center">
        <Button
          className={cx(styles.btn, "gray")}
          onClick={() => {
            history.push(ShopsPathsEnum.SHOP_LIST);
          }}
          shape="round"
        >
          ＴＯＰページへ戻る
        </Button>
      </div>
    </div>
  );
};

export default memo(BankTransfer);
