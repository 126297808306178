import { memo } from "react";

import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";

import { convertMoney } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppSelector } from "@app/redux/store";

import styles from "./ButtonGacha.module.scss";

interface ButtonGachaProps {
  className?: string;
  numberGachaRoll: number;
  numberPointRoll: number;
  handleClick: (numberGacha: number, numberPoint: number) => void;
  isDisable?: boolean;
}

const ButtonGacha = ({
  numberPointRoll,
  numberGachaRoll,
  className,
  handleClick,
  isDisable,
}: ButtonGachaProps) => {
  const { md } = useBreakpoint();
  const {
    auth: { isMaintenanceGacha },
  } = useAppSelector((state: RootState) => state);

  return (
    <div
      aria-hidden
      className={cx(styles.btnGacha, className, {
        [styles.btnGacha1]: numberGachaRoll === 1,
        [styles.btnGacha10]: numberGachaRoll === 10,
        [styles.isDisable]: isMaintenanceGacha || isDisable,
      })}
      onClick={() => {
        if (!isDisable && !isMaintenanceGacha) {
          handleClick(numberGachaRoll, numberPointRoll);
        }
      }}
    >
      <div className={styles.btnPointText}>
        {numberGachaRoll === 1 ? "ガチャ" : "10連ガチャ"}
      </div>
      <div className="px-6 full-width">
        <div className={cx(styles.btnPoint, "mx-auto")}>
          <span
            className={`${
              md ? "font-23 mr-3" : "font-16 mr-2"
            } icon-point-icon `}
          >
            <span className="path1" />
            <span className="path2" />
          </span>
          {convertMoney(numberPointRoll)}
        </div>
      </div>
    </div>
  );
};

export default memo(ButtonGacha);
