/* eslint-disable no-nested-ternary */
/* eslint-disable no-irregular-whitespace */
import { useEffect, useState } from "react";

import { unwrapResult } from "@reduxjs/toolkit";
import { Col, Row, Typography } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useParams } from "react-router-dom";

import NoImage from "@app/assets/images/no_image_card.png";
import Button from "@app/components/atoms/Button/Button";
import CardZoom from "@app/components/atoms/CardZoom/CardZoom";
import Image from "@app/components/atoms/Image/Image";
import Label from "@app/components/atoms/Label/Label";
import LoadingSpinner from "@app/components/atoms/LoadingSpinner/LoadingSpinner";
import Helmet from "@app/components/molecules/Helmet/Helmet";
import { PAGE_INFO } from "@app/constants/constants";
import { StaticPagePathsEnum } from "@app/features/static-page/static-page";
import { getNameCard, isExistCardImage } from "@app/helpers/util.helper";
import { RootState } from "@app/redux/root-reducer";
import { useAppDispatch, useAppSelector } from "@app/redux/store";

import ZoomDialog from "../../components/ZoomDialog/ZoomDialog";
import {
  CARD_TYPE,
  getListCard,
  getPackDetail,
  getShopDetail,
  MAX_CARD_MEDIUM_HIT_PC,
  MAX_CARD_MEDIUM_HIT_SP,
  MAX_CARD_SMALL_FAIL,
  ShopsPathsEnum,
} from "../../shops";
import { Card, ListCard } from "../../types/shops.types";
import styles from "./ListCardScreen.module.scss";

const ListCardScreen = () => {
  const { sm, md } = useBreakpoint();
  const [isOpenZoomDialog, setIsOpenZoomDialog] = useState<boolean>(false);
  const [cardSelected, setCardSelected] = useState<Card | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasCard, setHasCard] = useState<boolean>(false);
  const {
    shops: { packDetail },
  } = useAppSelector((state: RootState) => state);

  const { id, packId } = useParams<{
    id: string;
    packId: string;
  }>();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { shopDetail } = useAppSelector((state: RootState) => state.shops);

  const [listCardBigHit, setListCardBigHit] = useState<Card[]>([]);
  const [listCardMediumHit, setListCardMediumHit] = useState<ListCard | null>(
    null
  );
  const [listCardSmallHit, setListCardSmallHit] = useState<Card[]>([]);
  const [listCardFail, setListCardFail] = useState<Card[]>([]);

  const randomRecord = (data: ListCard) => {
    return [...data.cards].sort(() => Math.random() - 0.5);
  };

  useEffect(() => {
    if (id) {
      dispatch(getShopDetail(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (md !== undefined) {
      setIsLoading(true);
      const fetchData = () => {
        dispatch(
          getListCard({
            shopId: id,
            packId,
            params: {
              types: [CARD_TYPE.BIG_HIT],
            },
          })
        )
          .then(unwrapResult)
          .then(data => {
            setListCardBigHit(data.cards);
            setIsLoading(false);
          });
        dispatch(
          getListCard({
            shopId: id,
            packId,
            params: {
              types: [CARD_TYPE.MEDIUM_HIT],
              page: 1,
              limit: md ? MAX_CARD_MEDIUM_HIT_PC : MAX_CARD_MEDIUM_HIT_SP,
              use_page: true,
            },
          })
        )
          .then(unwrapResult)
          .then(data => {
            setIsLoading(false);
            setListCardMediumHit(data);
          });
        dispatch(
          getListCard({
            shopId: id,
            packId,
            params: {
              types: [CARD_TYPE.SMALL_HIT],
              page: 1,
              use_page: true,
              limit: MAX_CARD_SMALL_FAIL,
            },
          })
        )
          .then(unwrapResult)
          .then(data => {
            setIsLoading(false);
            setListCardSmallHit(randomRecord(data));
          });
        dispatch(
          getListCard({
            shopId: id,
            packId,
            params: {
              types: [CARD_TYPE.FAIL],
              page: 1,
              use_page: true,
              limit: MAX_CARD_SMALL_FAIL,
            },
          })
        )
          .then(unwrapResult)
          .then(data => {
            setIsLoading(false);
            setListCardFail(data.cards);
          });
      };

      dispatch(
        getListCard({
          shopId: id,
          packId,
          params: {
            page: 1,
            use_page: true,
          },
        })
      )
        .then(unwrapResult)
        .then(data => {
          if (data.cards?.length) {
            fetchData();
            setHasCard(true);
          } else {
            setHasCard(false);
            setIsLoading(false);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [md]);

  useEffect(() => {
    dispatch(getPackDetail({ shopId: id, packId }));
  }, [dispatch, packId, id]);

  const fetchMoreData = () => {
    if (!listCardMediumHit?.cards.length) return;
    const cardMediumLimit = md
      ? MAX_CARD_MEDIUM_HIT_PC
      : MAX_CARD_MEDIUM_HIT_SP;
    dispatch(
      getListCard({
        shopId: id,
        packId,
        params: {
          types: [CARD_TYPE.MEDIUM_HIT],
          page:
            Math.round(listCardMediumHit?.cards.length / cardMediumLimit) + 1,
          limit: cardMediumLimit,
          use_page: true,
        },
      })
    )
      .then(unwrapResult)
      .then(data => {
        setListCardMediumHit({
          ...listCardMediumHit,
          cards: [...(listCardMediumHit?.cards ?? []), ...data.cards],
        } as ListCard);
      });
  };

  return (
    <>
      <Helmet
        title={
          packDetail?.title && shopDetail?.name
            ? `${shopDetail.name}｜${packDetail.title}｜カード詳細｜トレビル`
            : ""
        }
        description={
          shopDetail?.name
            ? `${shopDetail.name}${PAGE_INFO["UA-0004"].description}`
            : ""
        }
      />
      <div className={cx(styles.root)}>
        <div className={cx(styles.containerBox)}>
          <div className={cx(styles.container)}>
            <div className={cx("d-flex", md ? "gap-55" : "gap-24")}>
              <Button
                shape="round"
                className="gray btn-small"
                withLeftArrow
                onClick={() =>
                  history.push(
                    ShopsPathsEnum.PACK_DETAILS.replace(":shopId", id).replace(
                      ":packId",
                      packId
                    )
                  )
                }
              >
                戻る
              </Button>
              <Typography className={cx(md ? "font-15" : "font-12")}>
                ※
                返品につきまして、掲載されている画像と異なる品目の商品が届いた場合のみとさせていただいております。
                <br />
                詳しい詳細は
                <Typography.Link href={StaticPagePathsEnum.COMMERCIAL_LAW}>
                  特定商取引法に基づく表記
                </Typography.Link>
                をご参照ください。
              </Typography>
            </div>
            {!isLoading ? (
              <>
                {!hasCard ? (
                  <div>
                    <p
                      className={`text-center text-primary font-${
                        md ? 16 : 14
                      } mt-15`}
                    >
                      検索結果がありません。
                    </p>
                  </div>
                ) : (
                  <>
                    {!!listCardBigHit?.length && (
                      <>
                        <div className={sm ? "mt-4 mb-6" : "my-3"}>
                          <Label content="大当たり" bgColor="red" />
                        </div>
                        <div className={cx(styles.bigCardBox)}>
                          <Row
                            className="full-width"
                            gutter={[{ xs: 2, md: 14 }, 0]}
                            justify="center"
                          >
                            {listCardBigHit?.map(item => (
                              <Col
                                className="flex-justify-center"
                                span={md ? 5 : 8}
                              >
                                <CardZoom
                                  isShowButtonZoom={isExistCardImage(
                                    item.card_images,
                                    item.type
                                  )}
                                  className="big"
                                  imageUrl={item.image_url}
                                  title={getNameCard(item, true)}
                                  handleZoomCard={() => {
                                    setIsOpenZoomDialog(true);
                                    setCardSelected(item);
                                  }}
                                />
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </>
                    )}
                    {!!listCardMediumHit?.cards?.length && (
                      <>
                        <div className={sm ? "mt-6 mb-11" : "mt-3 mb-4"}>
                          <Label content="中当たり" bgColor="yellow" textDark />
                        </div>
                        <InfiniteScroll
                          dataLength={listCardMediumHit?.cards.length ?? 0}
                          next={fetchMoreData}
                          hasMore={
                            listCardMediumHit?.cards?.length <
                            listCardMediumHit?.page_info?.total_count
                          }
                          loader={<LoadingSpinner />}
                        >
                          <Row
                            gutter={[{ xs: 2, md: 14 }, 0]}
                            className={cx(styles.bigCardBox)}
                          >
                            {listCardMediumHit?.cards?.map(item => (
                              <Col
                                span={sm ? 4 : 6}
                                className={cx(styles.mediumCardImgBox)}
                              >
                                <CardZoom
                                  isShowButtonZoom={isExistCardImage(
                                    item.card_images,
                                    item.type
                                  )}
                                  className="medium"
                                  title={getNameCard(item)}
                                  imageUrl={item.image_url}
                                  handleZoomCard={() => {
                                    setIsOpenZoomDialog(true);
                                    setCardSelected(item);
                                  }}
                                />
                              </Col>
                            ))}
                          </Row>
                        </InfiniteScroll>
                      </>
                    )}

                    {!!listCardSmallHit?.length && (
                      <>
                        <div className={sm ? "mt-4 mb-6" : "mt-1 mb-4"}>
                          <Label content="小当たり一覧" bgColor="blue" />
                        </div>
                        <div className={cx(styles.cardBoxOverFlow)}>
                          {listCardSmallHit?.map(item => (
                            <Image
                              className={cx(styles.smallCardImg)}
                              src={item.image_url ?? NoImage}
                            />
                          ))}
                        </div>
                      </>
                    )}
                    {!!listCardFail?.length && (
                      <>
                        <div className={sm ? "mb-6" : "mb-4"}>
                          <Label content="ハズレ一覧" bgColor="black" />
                        </div>
                        <div className={cx(styles.cardBoxOverFlow, "mb-2")}>
                          {listCardFail?.map(item => (
                            <Image
                              className={cx(styles.smallCardImg)}
                              src={item.image_url ?? NoImage}
                            />
                          ))}
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      </div>
      {cardSelected && isOpenZoomDialog && (
        <ZoomDialog
          isOpenDialog={isOpenZoomDialog}
          handleCancel={() => setIsOpenZoomDialog(false)}
          card={cardSelected}
        />
      )}
    </>
  );
};

export default ListCardScreen;
