import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { get, isEmpty } from "lodash";

import { AuthPathsEnum } from "@app/features/auth/auth";
import { isCardAlmostExpired } from "@app/helpers/util.helper";

import { ParamsDef } from "../../../types/route.types";
import api from "../api/shops.api";
import { SHOPS_FEATURE_KEY } from "../constants/shops.keys";
import {
  ShopPacksResponseDef,
  ParamsListPacks,
  ShopDetail,
  ShopList,
  Banners,
  Pack,
  ListCard,
  ShopListUser,
  CardToDeliveryDef,
  Labels,
} from "../types/shops.types";

export type ShopsState = {
  shopList: ShopList | null;
  bannerList: Banners | null;
  labelList: Labels | null;
  shopDetail: ShopDetail | null;
  shopPacks: ShopPacksResponseDef | null;
  packDetail: Pack | null;
  listCard: ListCard | null;
  shopListUser: ShopListUser | null;
  listCardUser: ListCard | null;
  isGettingCards: boolean;
  isGettingPacks: boolean;
};

const initialState: ShopsState = {
  shopList: null,
  bannerList: null,
  labelList: null,
  shopDetail: null,
  shopPacks: null,
  packDetail: null,
  listCard: null,
  shopListUser: null,
  listCardUser: null,
  isGettingCards: false,
  isGettingPacks: false,
};

export const getShopList = createAsyncThunk(
  `${SHOPS_FEATURE_KEY}/getShopList`,
  async (params: ParamsDef, { rejectWithValue }) => {
    try {
      const response = await api.shopListApi(params);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getBannerList = createAsyncThunk(
  `${SHOPS_FEATURE_KEY}/getBannerList`,
  async (params: ParamsDef, { rejectWithValue }) => {
    try {
      const response = await api.bannerListApi(params);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getLabelList = createAsyncThunk(
  `${SHOPS_FEATURE_KEY}/getLabelList`,
  async (params: ParamsDef, { rejectWithValue }) => {
    try {
      const response = await api.labelListApi(params);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getShopDetail = createAsyncThunk(
  `${SHOPS_FEATURE_KEY}/getShopDetail`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await api.getShopDetailApi(id);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getShopPacks = createAsyncThunk(
  `${SHOPS_FEATURE_KEY}/getShopPacks`,
  async (params: ParamsListPacks, { rejectWithValue }) => {
    try {
      const response = await api.getShopPacksApi(params);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getPackDetail = createAsyncThunk(
  `${SHOPS_FEATURE_KEY}/getPackDetail`,
  async (
    {
      shopId,
      packId,
    }: {
      shopId: string;
      packId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getPackDetailApi({ shopId, packId });
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getListCard = createAsyncThunk<
  ListCard,
  {
    shopId: string;
    packId: string;
    params: ParamsDef;
  }
>(
  `${SHOPS_FEATURE_KEY}/getListCard`,
  async ({ shopId, packId, params }, { rejectWithValue }) => {
    try {
      const response = await api.getListCardApi({ shopId, packId, params });
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getCardListUser = createAsyncThunk(
  `${SHOPS_FEATURE_KEY}/getCardListUser`,
  async (
    {
      shopId,
      params,
    }: {
      shopId: string;
      params?: ParamsDef;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.getCardListUserApi({
        shopId,
        params,
      });
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getShopListUser = createAsyncThunk(
  `${SHOPS_FEATURE_KEY}/getShopListUser`,
  async (params: ParamsDef, { rejectWithValue }) => {
    try {
      const response = await api.getShopListUserApi(params);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const cardToDelivery = createAsyncThunk(
  `${SHOPS_FEATURE_KEY}/cardToDelivery`,
  async (data: CardToDeliveryDef, { rejectWithValue }) => {
    try {
      const response = await api.cardToDeliveryApi(data);
      return response.data;
    } catch (err) {
      const error = get(err, "response.data");
      if (!error) {
        throw err;
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

const shopSlice = createSlice({
  name: SHOPS_FEATURE_KEY,
  initialState,
  reducers: {
    updateListCardUser: (state, action) => {
      if (!action.payload) {
        state.listCardUser = null;
      } else {
        state.listCardUser = {
          page_info: action.payload.page_info,
          cards: [
            ...(state.listCardUser?.cards ?? []),
            ...action.payload.cards,
          ],
        };
      }
    },
    updateGettingCards: (state, action) => {
      state.isGettingCards = action.payload;
    },
    updateListPacks: (state, action) => {
      if (!action.payload) {
        state.shopPacks = null;
      } else {
        state.shopPacks = {
          page_info: action.payload.page_info,
          packs: [...(state.shopPacks?.packs ?? []), ...action.payload.packs],
        };
      }
    },
    updateGettingPacks: (state, action) => {
      state.isGettingPacks = action.payload;
    },
    resetPackDetail: state => {
      state.packDetail = null;
    },
    resetShopDetail: state => {
      state.shopDetail = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(getShopList.fulfilled, (state, action) => {
      state.shopList = action.payload;
    });
    builder.addCase(getShopList.rejected, state => {
      state.shopList = null;
    });
    builder.addCase(getBannerList.fulfilled, (state, action) => {
      state.bannerList = action.payload;
    });
    builder.addCase(getBannerList.rejected, state => {
      state.bannerList = null;
    });
    builder.addCase(getLabelList.fulfilled, (state, action) => {
      state.labelList = action.payload;
    });
    builder.addCase(getLabelList.rejected, state => {
      state.labelList = null;
    });

    builder.addCase(getShopDetail.fulfilled, (state, action) => {
      if (isEmpty(action.payload)) {
        window.location.href = AuthPathsEnum.NOT_FOUND;
      } else {
        state.shopDetail = action.payload;
      }
    });
    builder.addCase(getShopDetail.rejected, state => {
      state.shopDetail = null;
    });

    builder.addCase(getPackDetail.fulfilled, (state, action) => {
      if (isEmpty(action.payload)) {
        window.location.href = AuthPathsEnum.NOT_FOUND;
      } else {
        state.packDetail = action.payload;
      }
    });
    builder.addCase(getPackDetail.rejected, state => {
      state.packDetail = null;
    });

    builder.addCase(getListCard.pending, state => {
      state.listCard = null;
    });
    builder.addCase(getListCard.fulfilled, (state, action) => {
      state.listCard = action.payload;
    });
    builder.addCase(getListCard.rejected, state => {
      state.listCard = null;
    });

    builder.addCase(getShopListUser.pending, state => {
      state.shopListUser = null;
    });
    builder.addCase(
      getShopListUser.fulfilled,
      (state, action: PayloadAction<ShopListUser>) => {
        const shopsList = action.payload;
        const shopsConvert = shopsList.shops.map(item => {
          return {
            ...item,
            has_card_exchange: isCardAlmostExpired(
              item.oldest_sold_card_gacha_at
            ),
          };
        });
        state.shopListUser = { ...shopsList, shops: shopsConvert };
      }
    );
    builder.addCase(getShopListUser.rejected, state => {
      state.shopListUser = null;
    });
  },
});

export const {
  updateListCardUser,
  updateGettingCards,
  updateGettingPacks,
  updateListPacks,
  resetPackDetail,
  resetShopDetail,
} = shopSlice.actions;

export const shopsReducer = shopSlice.reducer;
