import { AxiosResponse } from "axios";

import { api } from "@app/api/api";

import { GachaHistoriesEnum } from "../constants/gacha-histories.endpoints";
import {
  GachaHistoriesDataDef,
  ParamsGachaHistoriesDetail,
  ExchangeCardDataDef,
} from "../gacha-histories";

const postGachaHistoriesApi = (
  data: GachaHistoriesDataDef
): Promise<AxiosResponse> => {
  const url = GachaHistoriesEnum.POST_GACHA;
  return api.post(url, data);
};

const getGachaHistoriesDetailApi = ({
  id,
  params,
}: {
  id: string;
  params: ParamsGachaHistoriesDetail;
}): Promise<AxiosResponse> => {
  const url = GachaHistoriesEnum.GET_GACHA_DETAIL.replace(":id", id);
  return api.get(url, {
    params,
  });
};

const exchangeCardToPointApi = (
  data: ExchangeCardDataDef
): Promise<AxiosResponse> => {
  const url = GachaHistoriesEnum.EXCHANGE_CARDS_TO_POINT;
  return api.post(url, data);
};

const getPackNumberRollUserApi = ({
  pack_id,
}: {
  pack_id: string;
}): Promise<AxiosResponse> => {
  const url = GachaHistoriesEnum.GET_PACK_NUMBER_ROLL_USER;
  return api.get(url, {
    params: {
      pack_id,
    },
  });
};

const gachaHistoriesApi = {
  postGachaHistoriesApi,
  getGachaHistoriesDetailApi,
  exchangeCardToPointApi,
  getPackNumberRollUserApi,
};

export default gachaHistoriesApi;
