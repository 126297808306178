/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line filenames/match-regex
import dayjs from "dayjs";

import { UserAddress } from "@app/features/auth/types/auth.types";
import {
  GachaHistories,
  URL_ANIMATIONS_NORMAL,
} from "@app/features/gacha-histories/gacha-histories";
import {
  Card,
  CardImage,
  CARD_TYPE,
  TIME_AUTO_EXCHANGE_CARD,
  TIME_SHOW_ALERT_EXCHANGE_CARD,
} from "@app/features/shops/shops";

/**
 * Check if a string looks like an external URL
 */
export const isURL = (str: string) => {
  return /http|www/.test(str);
};

/**
 * A promise to delay an async function
 * @param ms how many milliseconds to wait
 */
export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const getInitials = (name: string, maxChar: number) => {
  return name
    .split(/\s/)
    .map(word => word[0])
    .join("")
    .substr(0, maxChar)
    .toUpperCase();
};

/**
 * Scroll to top of screen smoothly,
 * or fallback to instant scroll to top
 */
export const scrollToTop = () => {
  try {
    // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  } catch (error) {
    // fallback for older browsers
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }
};

export const objectKeyByValue: any = (obj: any, val: any) =>
  Object.entries(obj).find(i => i[1] === val)?.[0];

export const convertMoney = (num?: number) => {
  return String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const convertPhoneNumber = (num: number | string) =>
  num ? String(num).replace(/(\d)(?=(\d{4})+(?!\d))/g, "$1-") : "-";

export const getUrlVideo = (gachaData: GachaHistories) => {
  let typeCard: string | undefined;
  const isLargestPointFlag = gachaData.cards.some(
    item => !!item.largest_point_flag
  );

  if (isLargestPointFlag) {
    return URL_ANIMATIONS_NORMAL.top;
  }

  Object.values(CARD_TYPE).forEach(item => {
    if (gachaData?.cards?.some(card => card.type === item)) {
      if (!typeCard) {
        typeCard = item;
      }
    }
  });

  if (!typeCard) return undefined;
  return URL_ANIMATIONS_NORMAL[typeCard];
};

export const getUserAddress = (userAddress: UserAddress | undefined) => {
  if (!userAddress) return "";
  return `${userAddress?.prefecture?.name ?? ""}${userAddress?.city ?? ""}${
    userAddress?.address ?? ""
  }${userAddress?.apartment_name_room_number ?? ""}`;
};

export const textOverflow = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    const maxText = text.substr(0, maxLength);
    return `${maxText}...`;
  }
  return text;
};

export const getNameCard = (card: Card, isShowRate = false) => {
  if (!card) return "";
  return (
    <>
      <span>{card.name}</span>
      {card.number_order && (
        <span className="order-number">{card.number_order}</span>
      )}
      <span>
        {card.model_number
          ? `(${card.model_number}${
              isShowRate && card.rare?.name ? ` ${card.rare?.name}` : ""
            })`
          : ""}
      </span>
    </>
  );
};

export const isCardAlmostExpired = (gachaAt: string) =>
  gachaAt
    ? dayjs(gachaAt).add(TIME_AUTO_EXCHANGE_CARD, "day").diff(dayjs(), "day") <=
      TIME_SHOW_ALERT_EXCHANGE_CARD
    : false;

export const isExistCardImage = (cardImages: CardImage[], type: string) =>
  [CARD_TYPE.BIG_HIT, CARD_TYPE.MEDIUM_HIT].includes(type) &&
  !!cardImages.length;
