import { memo } from "react";

import cx from "classnames";

import Image from "@app/components/atoms/Image/Image";

import styles from "./SlideImage.module.scss";

interface SlideImageProps {
  checked: boolean;
  name: string;
  src?: string;
  value: string;
  handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
}

const SlideImage = ({
  name,
  checked,
  value,
  handleChange,
  src,
}: SlideImageProps) => {
  return (
    <label htmlFor={`${value}-${name}`}>
      <input
        checked={checked}
        type="checkbox"
        hidden
        onChange={handleChange}
        name={name}
        id={`${value}-${name}`}
        value={value}
      />
      <div className={cx(checked && styles.index)} />
      <Image
        style={{ cursor: "pointer" }}
        src={src}
        width="100%"
        height="100%"
      />
    </label>
  );
};

export default memo(SlideImage);
